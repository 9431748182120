'use strict';
import angular from 'angular';
import $ from 'jquery';
import moment from 'moment';
import _ from 'lodash';
import {WidgetType} from "coreModules/design/widget/design.widget.constants";
import {MomentDateFormat} from 'coreModules/daterange/base/daterange.constants';
import EmbedSparkline from "grokModules/src/modules/core/datatable/components/EmbedSparkline";
import {ChartPlotType} from "../../../design.widget.constants";
import {ChartFactory} from "../../chart/widget.chart.services";
import {UserThemes} from "../../../../../shared/scripts/app.constants";

angular.module('widget.datagrid.services', [])
    .constant('$DataGridEvents', $EventConstants())
    .factory('DataGridFactory', DataGridFactory)
    .factory('DataGridRender', DataGridRender);

function $EventConstants() {
    return {
        UPDATE_SCROLL_BODY_HEIGHT: 'DataGrid: UPDATE_SCROLL_BODY_HEIGHT',
        UPDATE_COLUMN_WIDTH: 'DataGrid: UPDATE_COLUMN_WIDTH',
        UPDATE_ALL_SCROLL_BODY_HEIGHT: 'DataGrid: UPDATE_ALL_SCROLL_BODY_HEIGHT'
    };
}

/**
 * @ngInject
 */
function DataGridFactory(
    $q,
    $timeout,
    $window,
    $rootScope,
    $compile,
    PubSub,
    AppFactory,
    DataGridModelFactory,
    ColumnFormat,
    PostProcessType,
    DrawOption,
    TimeGrouping,
    DesignFactory,
    WidgetFactory,
    WidgetUtilService,
    WidgetBuilderUIService,
    WidgetSortUtilService,
    WidgetQueryParamService,
    DataSourceFactory,
    DrawOptionFactory,
    DataGridRender,
    WidgetDataSourceFactory,
    ReportStudioTemplateDataService,
    ExportBuilderDashboardService,
    DataSourceType,
    UIColor,
    $DatagridTagPickerEvents,
    UITagPickerFactory,
    TagPickerModelFactory,
    TagPickerSize,
    TapColorsService,
    gettextCatalog,
    WidgetData,
    ChartPlotType,
    ConditionalType,
    ChartFactory,
    UIFactory,
    UserThemes,
    ExportFactory,
    BigNumberFactory
) {
    return {
        setFixedHeader: setFixedHeader,
        updateScrollBody: updateScrollBody,
        updateAllScrollBodyHeight: updateAllScrollBodyHeight,
        getDTOptions: getDTOptions,
        getAllData: getAllData,
        formatComparison: formatComparison,
        isPaginating: isPaginating,
        resetSortArray: resetSortArray,
        getCloneOptions: getCloneOptions,
        includeShowTotalRow: includeShowTotalRow,
        hasMetricFilter: hasMetricFilter,
        fieldToHash: fieldToHash,
        getTable: getTable,
        getPercentOfTotal: getPercentOfTotal,
        getColumnTotalValue: getColumnTotalValue,
        shouldAddPercentMetric: shouldAddPercentMetric,
        isDataGridWithFixedHeaderSize: isDataGridWithFixedHeaderSize,
        showOverflowTruncateMessage: showOverflowTruncateMessage,
        removeEmptyPages: removeEmptyPages,
        isClassicPlotType: isClassicPlotType,
        canApplyWrapText: canApplyWrapText
    };

    /**
     *
     * @param options
     * @param widget
     * @returns {{}}
     */
    function getDTOptions(options, widget) {
        let metadata = widget.metadata;
        var dtOptions = {};
        dtOptions.tagsEnabled = false;
        dtOptions.autoWidth = false;
        dtOptions.processing = true;
        dtOptions.language = {};
        dtOptions.language.search = '_INPUT_';
        dtOptions.language.lengthMenu = gettextCatalog.getString('Show _MENU_');

        dtOptions.comparison = false;

        if (WidgetUtilService.hasPrimaryDateSelectedColumn(metadata)
            && !WidgetUtilService.hasPrimaryDateGroupedColumn(metadata)
            && !metadata.time_grouping) {
            metadata.time_grouping = TimeGrouping.GROUPING_DAILY;
        }

        dtOptions.dateFormat = metadata.time_grouping ? WidgetFactory.getDateFormats(metadata.time_grouping) : null;
        // Ability to set callback to action buttons in grid
        dtOptions.actionOptions = {};

        setResponsiveOptions(dtOptions, metadata);

        // Display length when paginating
        dtOptions.lengthMenu = [5, 10, 25, 50];

        // By default set item name to data source type, unless otherwise specified
        dtOptions.itemName = options.itemName || metadata.data_source.type;
        dtOptions = _.extend(options, dtOptions);
        setDTOptions(dtOptions, widget);

        // Append data source specific datatable options
        _.extend(dtOptions, WidgetDataSourceFactory.getDTOptions(metadata.data_source));

        // serverSide is only true if displaying all data
        // Setting serverSide to true will automatically query backend for sort.
        // We don't need this for anything other than 'all' rows, or preview data...

        /**
         * Handle returned data for Draw Table
         * @param json
         * @param fnCallback
         */
        function processData(json, fnCallback) {
            // Restangular must return an array when using getList so data
            // is sent back in an array with one element containing the desired object
            // But when returned as predefined_data, the object is already in the correct
            // format so no need to grab first
            var data;
            json = angular.copy(json);
            if (dtOptions.isSample) {
                // Data massage
                var tempData = angular.copy(json.data);
                json.data = {};
                json.data.aaData = tempData;

                data = json.data;

                // json data for a sample dataGrid could return no actual data
                if (_.isUndefined(data.aaData)) {
                    data.aaData = [];
                }
            }
            else {
                data = _.isArray(json.data) ? _.first(json.data) : json.data;
            }

            if (json.total_data) {
                options.total_data = json.total_data;
            }

            if (!_.isEmpty(data)) {

                // In order to assign a column of data for the responsive row control toggler
                // we need to pad the data with an empty key value pair (ex: [{'key1': 'value1', ...} => {'control': null, 'key1': 'value1', ...}]
                if (json.has_comparison_data) {
                    data.aaData = _.map(data.aaData, function(datum) {
                        var comparisonData = datum.prior_period ? datum.prior_period : {};
                        datum = datum.current_period ? datum.current_period : {};
                        pushComparisonData(metadata.data_columns.selected, datum, comparisonData);
                        datum.comparison_data = comparisonData;
                        return datum;
                    });
                }
                if (options.responsive !== false) {
                    _.each(data.aaData, function (datum) {
                        datum.control = null;
                    });
                }

                if (fnCallback) {
                    fnCallback(data);
                }
            }
            else {
                if (fnCallback) {
                    fnCallback({aaData: []});
                }
            }
            PubSub.emit($EventConstants().UPDATE_COLUMN_WIDTH + widget.id);
        }

        /**
         *
         * @param source
         * @param params
         * @param fnCallback
         */
        var getData = function (source, params, fnCallback) {
            // Find start and length in params to determine page
            var dtData;

            // Capture sort click
            var sortParams = params[2].value;
            var selectedColumns = metadata.data_columns.selected;

            dtOptions.multiSorting = sortParams.length > 1;

            if (WidgetBuilderUIService.isActive()) {
                WidgetSortUtilService.updateColumnSorting(metadata);
            }

            // If the params sort value does not match the metadata, then we are changing the sort options
            if (!widget.is_predefined && orderHasChanged(sortParams, selectedColumns, metadata)) {
                setOrder(sortParams, options, metadata);
                options.setIsQueryingDatagrid(true);
                options.rebuildTable();
                dtData = {aaData: []};
            }
            else if (metadata.load_all_data) {
                var sampleSize = _getDisplayLength(metadata);
                dtData = getDTData(widget, {sampleSize: sampleSize}, true);
            }
            else {
                var queryParams = {};

                // NOTE: Categories does not have a getSize function in the backend so we need to enforce a sample size
                // i.e. "All" display length is not allowed for categories
                if (metadata.data_source.data_view_name == DataSourceType.CATEGORY_DATA) {
                    queryParams.sample = getDisplayLength(metadata);
                }

                var start = _.find(params, {name: 'start'}).value;
                var length = _.find(params, {name: 'length'}).value;
                if (isPaginating(metadata)) {
                    queryParams.page = Math.floor(start / length) + ',' + length;
                }
                else {
                    queryParams.limit = length;
                }
                queryParams.datatable = true;

                // It is possible that we sort by a column that is not on the table which means options.order is empty
                if (_.isEmpty(options.sort_order)) {
                    // Use order against columns to determine sort by
                    var orders = _.find(params, {name: 'order'}).value;
                    var colIndex, sortDir, columnToSort;
                    metadata.sort_by = [];
                    metadata.sort_order = [];
                    var columns = _.find(params, {name: 'columns'});
                    _.each(orders, function (order) {
                        colIndex = order.column;
                        sortDir = order.dir;

                        columnToSort = columns.value[colIndex].data;
                        metadata.sort_by.push(columnToSort);
                        metadata.sort_order.push(sortDir);
                    });
                }
                else if (isPaginating(metadata)) {
                    setOrder(sortParams, options, metadata);
                }

                var search = _.find(params, {name: 'search'}).value.value; // returns an obj with value property

                let isQueryingDataGrid = !_.isUndefined(search) && !_.isEmpty(search);
                options.setIsQueryingDatagrid(isQueryingDataGrid);
                if (isQueryingDataGrid) {
                    queryParams.q = WidgetQueryParamService.sanitizeSearchString(search);
                }
                dtData = getDTData(widget, queryParams);
            }

            $q.resolve(dtData).then(function(json) {
                    processData(json, fnCallback);
                },
                function () {
                    fnCallback({aaData: []});
                });
        };

        // Only set serverSide in preview, or if all rows are required
        if (isPaginating(metadata) && !metadata.load_all_data && !options.isSample) {
            dtOptions.serverSide = true;
            // dtOptions.serverData will be called after datatable is initialized when serverSide = true
            dtOptions.serverData = getData;
        }
        else {

            var queryParams = {};

            // NOTE: Categories does not have a getSize function in the backend so we need to enforce a sample size
            // i.e. "All" display length is not allowed for categories
            if (metadata.data_source.data_view_name == DataSourceType.CATEGORY_DATA) {
                queryParams.sample = _getDisplayLength(metadata);
            }

            queryParams.limit = isPaginating(metadata) ? -1 : _getDisplayLength(metadata);
            queryParams.datatable = true;

            WidgetSortUtilService.updateColumnSorting(metadata);

            // dtOptions.data will be resolved before we can create this table
            if (options.isSample) {
                var sampleSize = _getDisplayLength(metadata);
                dtOptions.data = dtOptions.data || getDTData(widget, {sampleSize: sampleSize}, true);
            } else {
                if (metadata.needs_pagination) {
                    delete queryParams.limit;
                    queryParams.page = `${metadata.offset || 0}, ${metadata.draw_options.rows_per_page}`;
                }
                dtOptions.data = dtOptions.data || getDTData(widget, queryParams);
            }

            dtOptions.processData = processData;
        }

        if (widget.metadata?.dynamic?.predefined_data !== null) {
            if ('currency_discrepancy' in widget.metadata.dynamic.predefined_data) {
                widget.metadata.currency_discrepancy = widget.metadata.dynamic.predefined_data.currency_discrepancy;
            }

            if ('show_currency' in widget.metadata.dynamic.predefined_data) {
                widget.metadata.show_currency = widget.metadata.dynamic.predefined_data.show_currency;
            }
        }
        setDTColumns(dtOptions, widget);

        // Order needs to be determined after columns are set and it is
        // also possible to sort by a column not present
        var order = getOrder(dtOptions.columns, metadata);
        if (!_.isEmpty(order)) {
            dtOptions.order = order;
        }
        else if (DesignFactory.getIsExportingPage() || _.isEmpty(dtOptions.columns)) {
            // In export when sorting by a non-visible column, we need to disable it
            // completely or else it will sort by first column by default or if there
            // are no columns at all
            dtOptions.aaSorting = false;
        }

        return dtOptions;
    }

    /**
     * Tells us if the data grid should be responsive
     * @param dtOptions
     * @param metadata
     */
    function setResponsiveOptions(dtOptions, metadata) {
        var responsive = false;
        if (dataGridIsResponsive(metadata)) {
            responsive = {
                details: {
                    type: 'column',
                    target: -1
                }
            };

            if (metadata.draw_options[DrawOption.GRID_COLLAPSE_IN_MODAL]) {
                responsive.details.display = $.fn.dataTable.Responsive.display.modal({
                    header: function () {
                        return gettextCatalog.getString('Full Row Details');
                    }
                });
                responsive.details.renderer = $.fn.dataTable.Responsive.renderer.tableAll({
                    tableClass: 'table show-more-table'
                });
            }
        }

        dtOptions.responsive = responsive;
    }

    /**
     * Tells us if the data grid should be responsive
     * @param metadata
     * @returns {boolean}
     */
    function dataGridIsResponsive(metadata) {
        return !DesignFactory.getIsExportingPage()
            && metadata.draw_options[DrawOption.GRID_IS_RESPONSIVE];
    }

    /**
     * Builds the order object for datatable
     * @param columns
     * @param metadata
     * @returns {Array}
     */
    function getOrder(columns, metadata) {
        // NOTE: sort_by and sort_order are set from the metadata in the ctrl
        var sortBy = metadata.sort_by;
        var sortOrder = metadata.sort_order;
        var sortIndex;
        var orders = [];

        _.each(sortBy, function(field, index) {
            sortIndex = _.findIndex(columns, {data: field});
            if (sortIndex >= 0) {
                orders.push([sortIndex, sortOrder[index].toLowerCase()]);
            }
        });
        return orders;
    }

    /**
     * Set sort metadata and rebuild datagrid
     * @param orders
     * @param options
     * @param metadata
     */
    function setOrder(orders, options, metadata) {
        if (_.isEmpty(orders)) {
            resetSortArray(metadata);
            return;
        }

        metadata.sort_by = [];
        metadata.sort_order = [];
        _.each(orders, function (order) {
            metadata.sort_by.push(options.columns[order.column].data);
            metadata.sort_order.push(order.dir);
        });
    }

    /**
     * Set iDisplayLength on metadata
     *
     * @param length
     * @param metadata
     */
    function setLength(length, metadata) {
        metadata.draw_options.display_length = length;
    }

    /**
     * Reset sort array to first grouped by column
     * @param metadata
     */
    function resetSortArray(metadata) {
        if (!_.isEmpty(metadata.data_columns.grouped)) {
            metadata.sort_by = [metadata.data_columns.grouped[0].groupby_name_field];
            metadata.sort_order = ['asc'];
        } else {
            metadata.sort_by = [];
            metadata.sort_order = [];
        }
    }

    /**
     *
     * @param options
     * @returns {CopyOptions}
     */
    function getCloneOptions(options) {
        return DataGridModelFactory.getCopyOptions(options);
    }

    /**
     * Tell us if order has changed
     * @param orders
     * @param selectedColumns
     * @param metadata
     * @returns {boolean}
     */
    function orderHasChanged(orders, selectedColumns, metadata) {
        var sortBy = metadata.sort_by;
        var sortOrder = metadata.sort_order;
        var order;

        if (orders.length === sortBy.length && orders.length === sortOrder.length) {
            for (var i = 0; i < orders.length; i++) {
                order = orders[i];
                if (sortBy[i] !== selectedColumns[order.column].field || sortOrder[i] !== order.dir) {
                    return true;
                }
            }
            return false;
        } else {
            return true;
        }
    }

    /**
     * Does datagrid have pagination
     * @param metadata
     * @returns {*}
     */
    function isPaginating(metadata) {
      return (
        metadata.draw_options[DrawOption.GRID_PAGINATE] &&
        !metadata.draw_options[DrawOption.PIVOT_GRID] &&
        !ReportStudioTemplateDataService.getIsActive()
      );
    }

    /**
     * Does datagrid pivot grid
     * @param metadata
     * @returns {*}
     */
    function isPivotingGrid(metadata) {
        return metadata.draw_options[DrawOption.PIVOT_GRID];
    }

    /**
     * Does datagrid have multiple sorting
     * @param metadata
     * @returns {*}
     */
    function isMultiSorting(metadata) {
        if (metadata.sort_order.length !== metadata.sort_by.length) {
            console.error('sort_order and sort_by should have the same length');
        }
        return metadata.sort_order.length > 1;
    }

    /**
     * Should search be hidden in datasource
     * @param metadata
     * @returns {boolean}
     */
    function hasSearch(metadata) {
        // Hide search for seo datasource
        if (metadata.data_source.type === DataSourceType.SEO_WEBSITES) {
            return false;
        }
        return true;
    }

    /**
     * Tell us if datafrid has metric filter
     * @param widget
     */
    function hasMetricFilter(widget) {
        let metadata = widget.metadata;
        var filters = metadata.dynamic && !_.isEmpty(metadata.dynamic.filters) ? metadata.dynamic.filters : null;
        return !_.isEmpty(_.filter(filters, {is_metric: true}));
    }

    function getTable(widgetId) {
        return $('#widget-' + widgetId).find('table').DataTable();
    }
    /**
     * Number of records to show
     * @param metadata
     * @returns {number}
     * @private
     */
    function _getDisplayLength(metadata) {
        const recordsLength =  DesignFactory.getIsExportingPage()
        && !_.isNil(metadata.draw_options[DrawOption.REPORT_DISPLAY_LENGTH])
        && !metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA]
            ? metadata.draw_options[DrawOption.REPORT_DISPLAY_LENGTH]
            : metadata.draw_options[DrawOption.DISPLAY_LENGTH];
        return recordsLength === -1 ? WidgetData.MAX_DISPLAY_RECORDS : recordsLength;
    }

    /**
     * @param selectedColumns
     * @param datum
     * @param comparisonDatum
     */
    function pushComparisonData (selectedColumns, datum, comparisonDatum) {
        _.each(selectedColumns, function(selectedColumn) {
            if (datum && (selectedColumn.is_metric || selectedColumn.is_primary_date_field)) {
                selectedColumn.has_comparison = true;
                var originalValue = _.isObject(datum[selectedColumn.field]) ? datum[selectedColumn.field].value : datum[selectedColumn.field];
                datum[selectedColumn.field] = {
                    value: originalValue,
                    comparisonValue: comparisonDatum ? comparisonDatum[selectedColumn.field] : null
                };
            }
            else if (datum && _.isEmpty(datum[selectedColumn.field])) {
                datum[selectedColumn.field] = comparisonDatum[selectedColumn.field];
            }
        });
    }

    /**
     * Return the data for a datatable
     * @param widget
     * @param queryParams
     * @returns {{}}
     */
    function getDTData(widget, queryParams) {
        queryParams.aggregate = false;
        return WidgetDataSourceFactory.getData(widget, queryParams, null);
    }

    /**
     * Return all data for datagrid, ignoring pagination
     * @param widget
     * @returns {{}}
     */
    function getAllData(widget) {
        var queryParams = {datatable: true};
        return getDTData(widget, queryParams, null)
    }

    /**
     * Set default options for datatable
     * @param options
     * @param widget
     */
    function setDTOptions(options, widget) {
        let metadata = widget.metadata;
        options.language.zeroRecords = gettextCatalog.getString('No records found');
        options.language.processing = '<div class="dataTables_processing_backdrop"></div>';
        options.language.paginate = {
            first: gettextCatalog.getString('First'),
            last: gettextCatalog.getString('Last'),
            next: gettextCatalog.getString('Next'),
            previous: gettextCatalog.getString('Previous')
        };
        options.bSort = !metadata.draw_options[DrawOption.PIVOT_GRID];
        options.recordsInfoBadge = $('<div class="total-items label label-default"><span class="has-items" translate><span class="start"></span> ' + gettextCatalog.getString('to') + ' <span class="end"></span> ' + gettextCatalog.getString('of') + ' <span class="total"></span></span><span class="no-items" translate>No records found</span></div>');
        options.iDisplayLength = _getDisplayLength(metadata);
        options.displayPagination = isPaginating(metadata);
        options.multiSorting = isMultiSorting(metadata);
        // Only allow multiple sorting when in preview
        options.orderMulti = WidgetBuilderUIService.isActive();
        options.hasMetricFilter = hasMetricFilter(widget);
        options.hasSearch = hasSearch(metadata);

        // Table DOM option
        if (DesignFactory.getIsExportingPage()) {
            options.responsive = false;
            options.dom = 't';
        }
        // Display length "All"
        else if (options.displayPagination) {
            options.dom = widget.is_predefined ? 'rplft' : 'rpft';
        }
        else {
            options.dom = 'rt';
        }

        options.formatNumber = function(toFormat) {
            return toFormat.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g, "'"
            );
        };

        options.fnInitComplete = function (settings) {
            var oTable = this.api().table();
            var $tableWrapper = $(settings.nTableWrapper);

            // Table header elements
            var $searchInput = $tableWrapper.find('div.dataTables_filter');
            var $lengthMenu = $tableWrapper.find('div.dataTables_length');
            var $paginationDiv = $tableWrapper.find('div.dataTables_paginate');
            var $numRecords = options.recordsInfoBadge;

            firstDisplay();
            initDisplay();

            /**
             * Display function to only run on first display
             */
            function firstDisplay() {
                if (!DesignFactory.getIsExportingPage()) {
                    //Show initially invisible length menu
                    $lengthMenu.showV();

                    //Initialize display for the first time
                    if (options.isSample) {
                        $searchInput.hide();
                    }
                    else {
                        //Show initially invisible search input
                        $searchInput.showV();

                        //Add placeholder to search input
                        $searchInput.find('input').attr('placeholder', gettextCatalog.getString('Search data...'));
                    }
                    // Add records info badge on the dom
                    if (options.displayPagination) {
                        $tableWrapper.prepend($numRecords);
                    }

                    if (!options.responsive && options.displayPagination) {
                        wrapHeaderElements();
                    }
                }

                // Hide search filter and pagination divs if pivot grid and pagination is turned on
                if (options[DrawOption.PIVOT_GRID] && options[DrawOption.GRID_PAGINATE]) {
                    $searchInput.hide();
                    $paginationDiv.hide();
                }

                if(!options.hasSearch) {
                    $searchInput.hide();
                }

                // Append footer if required by draw option
                appendFooter(oTable, widget, options, settings);

                // If fixed header is enabled, update scroll body height to make scrolling work
                if (options.enableFixedHeader) {
                    updateScrollBodyHeight(widget, options, settings);
                }

                appendEmbededSparklineTooltip();
            }

            /**
             * Wrap the header contents of datatable for easier manipulation
             */
            function wrapHeaderElements() {
                $tableWrapper.addClass('has-header');
                $tableWrapper.prepend('<div class="dataTables_header"></div>');
                var $header = $tableWrapper.find('div.dataTables_header');

                _.each($(settings.nTable).prevAll(), function(el) {
                    $header.prepend($(el));
                });
            }

            /**
             * Re-initialize function at every draw
             */
            function initDisplay() {
                // Update records info
                setRecordsInfoBadge(options, settings, metadata);

                // Add tooltips, if any
                var $dataToolTip = $('[data-toggle="tooltip"]');
                $.core.main.tooltip($dataToolTip, {
                    placement: 'top'
                });
                $dataToolTip.css('z-index', 102);

                $('[data-toggle="popover"]').popover({
                    placement: 'top',
                    html: true,
                    trigger: 'hover',
                    container: 'body'
                });

                let stylesArray = getStyleString(options).split(';').filter(function (i) {
                    return i
                });
                if (canApplyAlternatingRowColors(options) && !WidgetUtilService.isGroupedColumnPlotType(options)) {
                    const currentUser = AppFactory.getUser();
                    const backgroundColor = UIColor.getGradientColor(currentUser.currentThemeColor, 10);
                    const textColor = UIColor.textColorWithBackgroundColor(backgroundColor);
                    stylesArray.push('background-color:' + backgroundColor);
                    stylesArray.push('color:' + textColor);
                }

                const widgetIdSelector = ReportStudioTemplateDataService.getIsActive() ? '#widgetID-' + widget.id : '#widget-' + widget.id;
                // setting the applied styles for header
                stylesArray.forEach(function (item, index) {
                    let styleSubArray = item.split(':');
                    if (!DesignFactory.getIsExportingPage() && !ReportStudioTemplateDataService.getIsActive()) {
                        $(widgetIdSelector + ' .dataTables_scrollHead .dataTables_scrollHeadInner .dataTable thead:first tr th').css(styleSubArray[0], styleSubArray[1]);
                    } else {
                        $(widgetIdSelector + ' .datagrid-display .widget-display .dataTable thead:first tr th').css(styleSubArray[0], styleSubArray[1]);
                    }
                });

                // set alternate row color
                if (canApplyAlternatingRowColors(options) && !WidgetUtilService.isGroupedColumnPlotType(options)) {
                    const currentThemeColor = AppFactory.getUser().currentThemeColor;
                    const currentThemeType = AppFactory.getUser().themeType;
                    const fontColor = UIColor.textColorWithBackgroundColor(currentThemeColor);
                    const bgColorEven = UIColor.getGradientColor(currentThemeColor,50);
                    const bgColorOdd = UIColor.getGradientColor(currentThemeColor,30);
                    const fontColorEven = UIColor.textColorWithBackgroundColor(bgColorEven);
                    const fontColorOdd = UIColor.textColorWithBackgroundColor(bgColorOdd);
                    const widgetBodyElement = widgetIdSelector + ' .dataTable tbody';

                    if (currentThemeType === UserThemes.TYPE_DARK || ExportFactory.isAdobePPTExport()) {
                        $(widgetBodyElement + ' .even td').css('cssText','background-color:'+bgColorOdd+' !important;color:'+fontColorOdd+' !important;');
                        $(widgetBodyElement + ' .odd td').css('cssText','background-color:'+bgColorEven+' !important;color:'+fontColorEven+' !important;');
                    } else {
                        $(widgetBodyElement + ' .even').css({'backgroundColor':bgColorOdd, 'color':fontColorOdd});
                        $(widgetBodyElement + ' .odd').css({'backgroundColor':bgColorEven, 'color':fontColorEven});
                    }
                    if(!DesignFactory.getIsExportingPage()) {
                        $(widgetBodyElement + ' td a').css({'text-decoration': 'underline'});
                    }
                    $(widgetBodyElement + ' td a').css({'color':fontColor});
                }

                let widgetElement;
                if (ReportStudioTemplateDataService.getIsActive()) {
                    widgetElement = angular.element(widgetIdSelector + ' .dataTable')[0]?.lastElementChild;
                } else {
                    widgetElement = angular.element(widgetIdSelector + ' .dataTables_scrollBody')[0]?.firstElementChild?.lastElementChild;
                }
                if (!canApplyWrapText(widget.metadata)) {
                    if (!DesignFactory.getIsExportingPage()) {
                        applyGridStyles(widgetElement, true);
                    }
                } else if (canFixLongGridStyles(widget.metadata)) {
                    fixGridLongStringStyles(widgetElement);
                }

                if (WidgetUtilService.isGroupedColumnPlotType(widget.metadata.draw_options)) {
                    let widgetHeaderElement;
                    if (!DesignFactory.getIsExportingPage()) {
                        if (ReportStudioTemplateDataService.getIsActive()) {
                            let reportStudioWidgetElement = angular.element(widgetIdSelector + ' .dataTable')[0];
                            widgetHeaderElement = reportStudioWidgetElement?.firstElementChild;
                            widgetElement = reportStudioWidgetElement?.lastElementChild;
                            if (widgetElement?.localName === 'tfoot') {
                                applyGridStyles(reportStudioWidgetElement?.childNodes[2], false, options);
                            }
                        } else {
                            widgetElement = angular.element(widgetIdSelector + ' .dataTables_scrollBody')[0]?.firstElementChild?.lastElementChild;
                            widgetHeaderElement = angular.element(widgetIdSelector + ' .dataTables_scrollHead .dataTables_scrollHeadInner')[0]?.firstElementChild?.firstElementChild;
                        }
                        applyGridStyles(widgetHeaderElement, false, options, widget.id);
                        applyGridStyles(widgetElement, false, options);
                    } else {
                        let widgetElements = angular.element(widgetIdSelector + ' .datagrid-display .widget-display .dataTable')[0];
                        widgetElements.childNodes.forEach(childNode => {
                            applyGridStyles(childNode, false, options);
                        });
                    }

                    // Add a header on top of the table for grouped column plot type
                    prependGroupedColumnHeader(widget);
                }

                    // Adjusting columns so header & body columns width will be same
                    oTable.columns.adjust().columns.adjust();
                    updateScrollBodyHeight(widget, options, settings);

                // Append footer if required by draw option
                appendFooterIfNeeded()
            }

            /**
             * Helper function to run post processing of order
             */
            function orderPostProcess(settings) {
                var orders = _.isEmpty(settings.aaSorting) ? options.order : settings.aaSorting;
                if (orders.length === 0) {
                    return;
                }
                var singleOrder = null;
                var isSingleSorting = true;

                // Currently when pivoting grid, order is forced to be [0, 'asc']
                if (isPivotingGrid(metadata)) {
                    singleOrder = [0, 'asc'];
                } else if (_.isArray(_.first(orders))) {
                    if (orders.length > 1) {
                        isSingleSorting = false;
                    } else {
                        singleOrder = _.first(orders);
                    }
                } else {
                    singleOrder = orders;
                }

                metadata.sort_by = [];
                metadata.sort_order = [];

                if (isSingleSorting) {
                    var selectedColumns = metadata.data_columns.selected;
                    _.each(selectedColumns, function (selectedColumn, index) {
                        if (index === singleOrder[0]) {
                            selectedColumn.sorting = singleOrder[1];
                            return true; // continue loop
                        }
                        selectedColumn.sorting = null;
                    });
                    if (isPivotingGrid(metadata)) {
                        metadata.sort_by.push(options.originalColumns[singleOrder[0]].data);
                        metadata.sort_order.push(singleOrder[1]);
                    } else {
                        metadata.sort_by.push(options.columns[singleOrder[0]].data);
                        metadata.sort_order.push(singleOrder[1]);
                    }

                    // Hide num icon of multiple sorting if feasible
                    $tableWrapper.find('div.sort-label').hide();
                } else {
                    _.each(orders, function(order) {
                        metadata.sort_by.push(options.columns[order[0]].data);
                        metadata.sort_order.push(order[1]);
                    });
                    // Show num icon of multiple sorting if feasible
                    $tableWrapper.find('div.sort-label').show();
                }

                if (options.isSample && !isPivotingGrid(metadata)) {
                    // Update selected column when creating
                    options.rebuildTable();
                }

                updateFooter();
            }

            function _updateDataGridColumn() {
                // Call columns.adjust() twice since we set autoWidth to be false, and this stops the column width handling
                // The second columns.adjust() will give the correct column width
                oTable.columns.adjust().columns.adjust();

                // After re-drawing, the footer may disappear if the datatable was already rendered
                appendFooterIfNeeded();
            }

            function updateFooter() {
                if (options.enableFixedHeader) {
                    $(settings.nScrollBody).find('table').find('tr.total-row').remove();
                    $(settings.nScrollHead).find('table').find('tr.total-row').remove();
                } else {
                    $(settings.nTable).find('tr.total-row').remove();
                }

                appendFooterIfNeeded();
            }

            /**
             * Validates if footer is necessary to be re-added in the table
             */
            function appendFooterIfNeeded() {
                if (includeShowTotalRow(widget)) {

                    var $table, $headTable;
                    if (options.enableFixedHeader) {
                        $table = $(settings.nScrollBody).find('table');
                        $headTable = $(settings.nScrollHead).find('table');
                    } else {
                        $table = $(settings.nTable);
                    }

                    !($table.has('tr.total-row').length
                        || ($headTable && $headTable.has('tr.total-row').length))
                    && appendFooter(oTable, widget, options, settings);
                }
            }

            function _updateDataGridBodyHeight() {
                _updateDataGridColumn();
                updateScrollBodyHeight(widget, options, settings);
            }

            function _registerEvents() {
                angular.element($window).on('resize', _updateDataGridBodyHeight);
                PubSub.on($EventConstants().UPDATE_COLUMN_WIDTH + widget.id, _updateDataGridColumn);
                PubSub.on($EventConstants().UPDATE_SCROLL_BODY_HEIGHT + widget.id, _updateDataGridBodyHeight);
                PubSub.on($EventConstants().UPDATE_ALL_SCROLL_BODY_HEIGHT, _updateDataGridBodyHeight);
            }

            function _unregisterEvents() {
                angular.element($window).off('resize', _updateDataGridBodyHeight);
                PubSub.off($EventConstants().UPDATE_COLUMN_WIDTH + widget.id, _updateDataGridColumn);
                PubSub.off($EventConstants().UPDATE_SCROLL_BODY_HEIGHT + widget.id, _updateDataGridBodyHeight);
                PubSub.off($EventConstants().UPDATE_ALL_SCROLL_BODY_HEIGHT, _updateDataGridBodyHeight);
            }

            oTable.on('destroy.dt', function () {
                _unregisterEvents();
            });

            //Table draw callback (opt to use this instead of fnDrawCallback since the latter is called twice)
            oTable.on('draw.dt', function () {
                initDisplay();
            });

            // Callback for when we sort the datatable by a column for
            oTable.on('order.dt', function (e, settings) {
                orderPostProcess(settings);
            });

            // If page is dynamic, length can be modified outside of edit widget
            if (DesignFactory.getCurrentPage().is_dynamic) {
                oTable.on('length.dt', function (e, settings, len) {
                    setLength(len, metadata)
                });
            }

            _registerEvents();
        };

        if (!canApplyWrapText(widget.metadata)) {
            options.headerCallback = function (thead) {
                $(thead).find("th").each(function () {
                    $(this).attr("title", $(this).text());
                });
            };
        }
        options.fnCreatedRow = function (row, data, index) {
            $(row).find('td.action-cell a.btn').each(function () {
                if ($(this).hasClass('edit-details-action')) {
                    options.editDetailsActionHandler($(this), row, data);
                } else if ($(this).hasClass('delete-action')) {
                    DataGridRender.bindDeleteRowAction($(this), options.actionOptions.delete);
                } else if ($(this).hasClass('copy-action')) {
                    DataGridRender.bindCopyRowAction($(this), options.actionOptions.copy);
                }
            });

            $(row).find('td .action-link, td .action-button').each(function () {
                options.postCreatedRowCallback && options.postCreatedRowCallback($(this), row, data, index);
            });

            $(row).find('td.datagrid-string-column').each(function () {
                $(this).attr('title', $(this).text());
            });

            if (AppFactory.getUser().isModuleAvailable('leads_management')) {
                _setUpTagging(widget, row, data);
            }

            //Add tooltips to any element that my have has-tooltip class
            $(row).find('td .has-tooltip').each(function () {
                //Add tooltips if any
                if ($(this).hasClass('has-tooltip')) {
                    $.core.main.tooltip($(this).parent());
                }
            });

            $(row).find("td").each(function () {
                if ($(this).hasClass("image-preview")) {
                    $(this).find('div').css({'overflow':'hidden'});
                }
            });

            //Heat map display for metrics columns
            $(row).find('td').each(function (colIndex, data) {
                let darkStyleText = null;
                let isComparisonEnabled = ChartFactory.isComparisonEnabled(widget.metadata) || widget.metadata.compare_to_prior_period;
                const currentThemeType = AppFactory.getUser().themeType;
                const column = options.columns[colIndex];

                if(WidgetUtilService.isHeatMapPlotType(widget.metadata.draw_options)) {
                    const gradientValues = widget.metadata?.heatmap_gradient || [];
                    if (column.bgColor !== null) {
                        let color = UIColor.textColorWithBackgroundColor(column.bgColor);
                        let bgColor = column.bgColor;
                        let isSampleData = WidgetFactory.useSampleData(widget.metadata);
                        if(gradientValues.includes(column.data)) {
                            let currentValue;
                            const currentData = options.data || widget.metadata.dynamic.raw_data?.data[0].aaData || widget.metadata.dynamic.predefined_data?.data.aaData;

                            currentValue = currentData[index][column.data];
                            if (isComparisonEnabled) {
                              currentValue =
                                currentData[index][column.data]?.value;
                              /**
                               * This below code is only for the dashboard purposes.
                               * In an ideal situation, grid_paginate will be false for report studio templates
                               *
                               * But, looks like due to some issue, that is set to true.
                               * So, handling that edge case by checking for RS template activeness
                               */
                              if (
                                options.grid_paginate &&
                                !ReportStudioTemplateDataService.getIsActive()
                              ) {
                                currentValue = isSampleData
                                  ? currentData[index][column.data]?.value
                                  : currentData[index]["current_period"][
                                      column.data
                                    ];
                              }
                            }
                            const gradientValue = getColumnGradientValue(currentData, column.data, currentValue, isComparisonEnabled, options.grid_paginate, isSampleData);
                            bgColor = UIColor.getGradientColor(column.bgColor, gradientValue);
                            color = UIColor.textColorWithBackgroundColor(bgColor);
                        }
                        if (currentThemeType === UserThemes.TYPE_DARK) {
                            darkStyleText =  'background-color:'+bgColor+' !important;color:'+color+' !important;';
                        }
                        darkStyleText !== null ? $(this).css('cssText',darkStyleText) : $(this).css({'backgroundColor':bgColor, 'color':color});
                        if (isComparisonEnabled) {
                            $(this).find('span.text-right span:first-child').css('cssText', 'color:'+color+';');
                        }
                    }
                }

                if (WidgetUtilService.isConditionalPlotType(widget.metadata.draw_options)) {
                    const conditionalValues = widget.metadata?.user_conditional_columns || [];
                    const columnName = column.data; // column.data contains the column name
                    let isColorApplied = false;
                    conditionalValues.forEach((conditionalValue) => {
                      if (
                        !_.isUndefined(conditionalValue["values"]) &&
                        !_.isEmpty(conditionalValue["values"]) &&
                        conditionalValue["values"].includes(columnName)
                      ) {
                        conditionalValue["rules"].forEach((condition) => {
                          if (!isColorApplied) {
                            let bgColor = condition["color"];
                            let color =
                              UIColor.textColorWithBackgroundColor(bgColor);
                            const currentData =
                              options?.data ||
                              widget.metadata.dynamic.raw_data?.data[0]
                                .aaData ||
                              widget.metadata.dynamic.predefined_data?.data
                                .aaData;
                            let currentValue = currentData[
                              index
                            ].hasOwnProperty("current_period")
                              ? currentData[index]["current_period"][columnName]
                              : currentData[index][columnName];
                            const canApplyColor = canApplyConditionalColor(
                              currentValue,
                              condition
                            );
                            if (canApplyColor) {
                              let textStyle =
                                "background-color:" +
                                bgColor +
                                ";color:" +
                                color +
                                ";";
                              if (currentThemeType === UserThemes.TYPE_DARK) {
                                textStyle =
                                  "background-color:" +
                                  bgColor +
                                  " !important;color:" +
                                  color +
                                  " !important;";
                              }
                              $(this).css("cssText", textStyle);
                              if (isComparisonEnabled) {
                                $(this)
                                  .find("span.text-right span:first-child")
                                  .css("cssText", "color:" + color + ";");
                              }
                              isColorApplied = true;
                            }
                          }
                        });
                      }
                    });
                }
                if (WidgetUtilService.isGroupedColumnPlotType(widget.metadata.draw_options) && column.className?.includes('grouped-column-#') && isComparisonEnabled) {
                    let colorClassPosition = column.className.indexOf('grouped-column-#');
                    let textColor = UIColor.textColorWithBackgroundColor(column.className.substring(colorClassPosition + 15, colorClassPosition + 22));
                    $(this).find('span.text-right span:first-child').css('cssText', 'color:'+textColor+';');
                }

            });

            //dark theme apply font color if there is no color for cell
            if (AppFactory.getUser().themeType === UserThemes.TYPE_DARK && WidgetUtilService.isGridFormattedPlotType(widget.metadata.draw_options)) {
                $(row).find("td").each(function () {
                    let nodeElement = $(this).find("p.value-container span.text-right span:first");
                    if (!nodeElement.css("color")) {
                        if (DesignFactory.getIsExportingPage()) {
                            nodeElement.css("cssText", "color:#000000");
                        } else {
                            nodeElement.css("cssText", "color:#ffffff");
                        }
                    }
                });
            }
        }

        function appendEmbededSparklineTooltip() {
            if(WidgetUtilService.isEmbeddedSparklinesPlotType(widget.metadata.draw_options) && !ReportStudioTemplateDataService.getIsActive()) {
                const fieldsDataSparkline = widget.metadata?.fields_to_embed_sparklines[0].values || [];
                const { selected } = widget.metadata.data_columns
                const filteredFields = getIndexAndValue(options.aoColumns, fieldsDataSparkline);
                const headerRow = $(`#widget-${widget.id} .dataTables_scrollHead .dataTables_scrollHeadInner .dataTable thead:first tr`);

                headerRow.each(function () {
                    $(this).find("th").each(function (index) {
                        if (filteredFields.some((el) => el.index === index && selected[index]?.is_ranking_metric)) {
                            const helpTitle = "Y Axis is reversed as this is a ranking metric. An upward graph indicates improvement in rank and a downward one indicates a drop.";
                            const helpIcon = '<span data-toggle="tooltip" class="icon icon-info-circle" title="' + helpTitle + '" data-placement="top" style="padding-right: 2px;z-index: 102;"></span>';
                            $(this).prepend(helpIcon);
                        }
                    });
                });
            }
        }

        // Check if the plot type requires embedded sparklines and fetch data
        if (
            WidgetUtilService.isEmbeddedSparklinesPlotType(widget.metadata.draw_options) &&
            !_.isEmpty(widget.metadata?.fields_to_embed_sparklines) &&
            widget.metadata.fields_to_embed_sparklines.every(field => field.values && field.values.length > 0) &&
            !widget.metadata.draw_options.grid_is_responsive &&
            !WidgetFactory.useSampleData(widget.metadata)
        ) {
            const promises = [
                setSparkLineData(widget),
            ];

            Promise.all(promises)
                .then((results) => {
                    const [embedSparklineData] = results; // Destructure results

                    if (embedSparklineData) {
                        options.embedSparklineData = embedSparklineData;
                    }

                    const widgetIdString = ReportStudioTemplateDataService.getIsActive() ? '#widgetID-' + widget.id : '#widget-' + widget.id;
                    const tableElement = $(widgetIdString).find('table');

                    if ($.fn.DataTable.isDataTable(tableElement)) {
                        const table = tableElement.DataTable();
                        if (table) {
                            table.on('draw', () => {
                                updateSparklinesInTable(table, options, widget); // Update sparklines on table draw
                            });
                            table.draw(); // Trigger initial draw
                        } else {
                            console.warn('DataTable instance not found for table:', widget.id);
                        }
                    } else {
                        console.warn('Table element not found:', widgetIdString);
                    }
                });
        }
    }


    // Function to update sparklines within the table
    function updateSparklinesInTable(table, options, widget) {
        const sparklineData = options.embedSparklineData;

        if (sparklineData) {
            const fieldsDataSparkline = widget.metadata?.fields_to_embed_sparklines[0].values || [];

            const filteredFields = getIndexAndValue(options.aoColumns, fieldsDataSparkline);
            const { selected } = widget.metadata.data_columns

            table.rows().every(function (rowIdx) {
                const row = this.node();
                const rowData = this.data(); // Get row data
                const groupKey = generateGroupKey(rowData, widget.metadata.data_columns.grouped);
                const matchedData = sparklineData[groupKey];

                if (matchedData) {
                    const sortMatchedData = _.sortBy(matchedData, 'log_date');
                    // Increase row height when embedding sparkline
                    $(row).css('height', '60px'); // Adjust the height value as needed

                    $(row).find('td').each((colIndex, cell) => {
                        const fieldDetails = getFieldDetails(colIndex, filteredFields);
                        if (fieldDetails) {
                            const rankMetric = selected[colIndex]?.is_ranking_metric ?? false;
                            setupSparkline(widget, rowIdx, cell, row, options, sortMatchedData, fieldDetails, rankMetric);
                        }
                    });
                }
            });
        }
    }

// Function to get field details for a given column index
    function getFieldDetails(colIndex, indexValuePairs) {
        return indexValuePairs.find(pair => pair.index === colIndex) || null;
    }

// Function to get index and value of columns to embed sparklines
    function getIndexAndValue(columns, inputArray) {
        return _.map(inputArray, (key) => {
            const index = _.findIndex(columns, { data: key });
            const value = _.get(columns, `[${index}].data`);
            const format = _.get(columns, `[${index}].format`);
            let label = _.get(columns, `[${index}].title`);

            // Remove HTML tags using a regular expression
            label = label?.replace(/<[^>]*>/g, '');
            return { index, value, label, format };
        });
    }

// Function to set up the sparkline
    function setupSparkline(widget, rowIdx, cell, row, options, matchedData, fieldDetails, rankMetric= false) {
        if (WidgetUtilService.isEmbeddedSparklinesPlotType(widget.metadata.draw_options)) {
            const sparklineDataStr = JSON.stringify(matchedData);
            const sparklineType = JSON.stringify(widget.metadata.draw_options.embed_sparklines_type === 'column' ? 'column' : 'line').replace(/'/g, "\\'");
            const sparklineField = JSON.stringify(fieldDetails.value);
            const sparklineFormat = JSON.stringify(fieldDetails.format);
            const sparklineLabel = JSON.stringify(fieldDetails.label);
            const isComparisonEnabled = ChartFactory.isComparisonEnabled(widget.metadata) || widget.metadata.compare_to_prior_period;
            const embedSparklineColor = JSON.stringify(options[DrawOption.GRID_FONT_PROPERTIES].text_color);

            const elStr = `<div><embed-sparkline v-props-sparkline-data='${sparklineDataStr}' v-props-field='${sparklineField}' v-props-format='${sparklineFormat}' v-props-label='${sparklineLabel}' v-props-sparkline-type='${sparklineType}' v-props-is-comparison-enabled='${isComparisonEnabled}' v-props-sparkline-color='${embedSparklineColor}' v-props-is-rank-metric='${rankMetric}'></embed-sparkline></div>`;
            const newScope = $rootScope.$new();
            const insertedEl = $compile(elStr)(newScope);
            $(cell).append(insertedEl);
        }
    }

// Utility function to generate the group key
    function generateGroupKey(rowData, groupedColumns) {
        return groupedColumns.map(column => rowData[column.field]).join(' - ');
    }

    function setSparkLineData(widget) {
        var deferred = $q.defer();

        BigNumberFactory.getSparkLineData(widget).then(function(json) {
            const data = json.sparkline_data || json.data;
            if (json.sparkline_data) {
                widget.metadata.data_columns.grouped = widget.metadata.data_columns.grouped.filter(fieldObj => {
                    // Check if the field property does not contain 'log_date'
                    return !fieldObj.field.includes('log_date');
                });
            }
            const groupedData = _.groupBy(data, obj => generateGroupKey(obj, widget.metadata.data_columns.grouped));
            deferred.resolve(groupedData);
        }).catch(function(error) {
            console.error('Error fetching sparkline data:', error);
            deferred.reject(error);
        });

        return deferred.promise;
    }

    /**
     * Helper function to get the gradient value for a column
     * @param data
     * @param field
     * @param value
     * @param isComparisonEnabled
     * @param isPaginationEnabled
     * @returns {string|number}
     */
    function getColumnGradientValue(data, field, value, isComparisonEnabled, isPaginationEnabled, isSampleData) {
       let gradientValue = 10;
       let highestToLowest = data.map(item => {
           if (isComparisonEnabled) {
             /**
              * This below code is only for the dashboard purposes.
              * In an ideal situation, grid_paginate will be false for report studio templates
              *
              * But, looks like due to some issue, that is set to true.
              * So, handling that edge case by checking for RS template activeness
              */
             if (
               isPaginationEnabled &&
               !ReportStudioTemplateDataService.getIsActive()
             ) {
               return isSampleData
                 ? item[field]?.value
                 : item["current_period"][field];
             }
             return item[field]?.value;
           }
           return item[field];
       }).sort((a, b) => convertTimeToNumber(b)-convertTimeToNumber(a));
       highestToLowest = highestToLowest.reduce( (unique, item) => (unique.includes(item) ? unique : [...unique, item]), [],);
       const  valueIndex = highestToLowest.indexOf(value?.value || value);
       gradientValue  = (gradientValue/highestToLowest.length)* valueIndex * 10 ;
       return !isNaN(gradientValue) ? gradientValue.toFixed() : 0;
    }

    /**
     * replace function will ONLY work for STRINGS
     * In order to make this function not break for non-strings
     * converting them to strings
     * @param t
     * @returns {*|number}
     */
    function convertTimeToNumber (t) {
        //remove Leading zeros and colon from time format
        if (_.isNull(t)) {
            return t;
        }
        t = (typeof t !== "string") ? t.toString() : t;
        return +t.replace(/:/g, '');
    }

    /**
     * Helper function check condition value apply to fill background color
     * @param value
     * @param condition
     * @param minValue
     * @param maxValue
     * @returns {boolean}
     */
    function canApplyConditionalColor(value, config) {
        const {condition, min, max} = config;
        value = value?.value || value;
        if (_.isNull(value) || _.isNull(min)) {
            return false;
        }

        switch(condition) {
            case ConditionalType.GREATER_THAN:
                return value > min ;
            case ConditionalType.LESS_THAN:
                return value < min ;
            case ConditionalType.BETWEEN:
                return value > min && value < max;
            default:
                return false;
        }
    }

    /**
     * Set up tagging for datagrid
     * @param widget
     * @param row
     * @param data
     * @private
     */
    function _setUpTagging (widget, row, data) {
        let metadata = widget.metadata;
        var tagged = []; // if already tagged, when evaluating is_taggable, skip it.
        _.forEach(metadata.data_columns.selected, function (column, columnIndex) {
            _.forEach(data.tags, function (tag) {
                if (column.is_taggable
                    && !_.isEmpty(data[column.field])
                    && data[column.field] === tag.value
                    && column.field === tag.column) {

                    var $cell = $(row).find('.' + fieldToHash(column.field));

                    $cell.addClass('tagged');
                    tagged[column.field] = tag.value;

                    var textColor = UIColor.textColorWithBackgroundColor(tag.color);
                    var $icon = $('<i class="icon icon-tag"></i>');
                    $icon.css('color', textColor);
                    var $divWrapper = $('<span class="badge tag-badge has-tooltip" title="'+ tag.value +'"></span>');
                    $divWrapper.append($icon);
                    $divWrapper.css('color', textColor);
                    $divWrapper.css('border-color', tag.color);
                    $divWrapper.css('background-color', tag.color);

                    var $cellInner = $cell.contents();
                    var $contentSpan = $('<span class="tag-child"></span>');
                    $contentSpan.append($cellInner);
                    $divWrapper.append($contentSpan);
                    $cell.append($divWrapper);

                    var $toolTipElement = $cell.find('.has-tooltip').get(0);
                    if ($toolTipElement) {
                        $($toolTipElement).attr('title', tag.tag);
                    }

                    if (window.isNUI) {
                        if (Permission.hasPermissionToWrite(Permission.moduleName.TAG)) {
                            _addTagPickerClickAction(widget, row, $cell, column, data, tag);
                        }
                    } else if (!WidgetBuilderUIService.isActive() && AppFactory.getUser().isAdmin()) {
                        _addTagPickerClickAction(widget, row, $cell, column, data, tag);
                    } else {
                        $divWrapper.addClass('no-click');
                    }
                }
            });

            if (window.isNUI && !Permission.hasPermissionToWrite(Permission.moduleName.TAG)) {
                return;
            }

            if (!tagged[column.field] && column.is_taggable && !_.isEmpty(data[column.field])) {
                var $cell = $(row).find('.' + fieldToHash(column.field));

                var $divWrapper = $('<span class="tag tag-container"><i class="tag icon icon-tag"></i></span>');

                $cell.addClass('taggable');
                var $cellInner = $cell.contents();
                var $contentSpan = $('<span class="tag-child"></span>');
                $contentSpan.append($cellInner);
                $divWrapper.append($contentSpan);
                $cell.append($divWrapper);

                if (window.isNUI) {
                    if (Permission.hasPermissionToWrite(Permission.moduleName.TAG)) {
                        _addTagPickerClickAction(widget, row, $cell, column, data, null);
                    }
                } else if (!WidgetBuilderUIService.isActive() && AppFactory.getUser().isAdmin()) {
                    _addTagPickerClickAction(widget, row, $cell, column, data, null);
                } else {
                    $divWrapper.addClass('no-click');
                }
            }
        });
    }

    /**
     * @param widget
     * @param row
     * @param column
     * @param data
     * @param tag
     * @private
     */
    function _addTagPickerClickAction(widget, row, $cell, column, data, tag) {
        let metadata = widget.metadata;
        var dataSourceType = metadata.data_source.type;
        var widgetId = widget.id;

        $cell.click(function () {

            var $$window = $($window);
            var windowHeight = $$window.height();
            var windowWidth = $$window.width();
            // default position, bottom, left aligned of the clicked cell
            var position = {
                x: $cell.offset().left,
                y: $cell.offset().top,
            };

            // see if there is enough room to place the tagPicker BELOW the cell in datagrid container
            var offsetY = position.y + $cell.outerHeight() + TagPickerSize.HEIGHT;
            position.y = offsetY > windowHeight
                ? position.y - $cell.outerHeight() // place it above the cell
                : position.y + $cell.outerHeight(); // place it below the cell

            // see if there is enough room to place the tagPicker left aligned with the cell in datagrid container
            var offsetX = position.x + TagPickerSize.WIDTH;
            position.x = offsetX > windowWidth
                ? position.x - (offsetX - windowWidth) - 20 // only remove the difference, 20px for browser scroll bar
                : position.x - TagPickerSize.WIDTH/3; // place it center aligned

            if (position.x < 0) {
                var offset = $cell.offset().left - (TagPickerSize.WIDTH/4 + TapColorsService.size/2);
                position.x = offset < 0 ? $cell.offset().left + TapColorsService.size/2 : $cell.offset().left; // left aligned with cell
            }

            // cell's exact position within the datagrid for initial loading animation
            var cellPosition = {
                x: $cell.offset().left,
                y: $cell.offset().top,
                width: $cell.outerWidth(),
                height: $cell.outerHeight()
            };

            // Set datatable
            UITagPickerFactory.setDatatable($(row).closest('table').DataTable());

            var event = TagPickerModelFactory.getTagPickerEventModel({
                cellPosition: cellPosition,
                dataSourceType: dataSourceType,
                position: position,
                value: column,
                rowData: data,
                tag: tag
            });
            PubSub.emit($DatagridTagPickerEvents.CELL_CLICKED + widgetId, event, true);
        });
    }

    /**
     *
     * @returns {boolean|boolean}
     */
    function isDataGridWithFixedHeaderSize() {
        const report = ExportBuilderDashboardService.getReport();
        const page = DesignFactory.getCurrentPage();
        if (report) {
            return report.metadata ? report.metadata.datagrid_with_fixed_header_size : true;
        }
        if (page && !_.isEmpty(page)) {
            return page.metadata ? page.metadata.datagrid_with_fixed_header_size : true;
        }
        return true;
    }

    /**
     *
     * @returns {boolean}
     * @private
     */
    function _isDashboardContext() {
        return !_.isEmpty(DesignFactory.getCurrentPage());
    }

    /**
     *  If total row is needed, we append a footer to the table
     * @param oTable
     * @param widget
     * @param options
     * @param settings
     */
    function appendFooter(oTable, widget, options, settings) {
        var drawOptions = widget.metadata.draw_options;
        if (includeShowTotalRow(widget)) {
            var showAtBottom = drawOptions[DrawOption.GRID_TOTAL_ROW_BOTTOM];
            var $table = $(settings.nTable);
            var $scrollHeadTable, $scrollBodyTable;
            if (options.enableFixedHeader) {
                $scrollHeadTable = $(settings.nScrollHead).find('table');
                $scrollBodyTable = $(settings.nScrollBody).find('table');
            }
            var $footer =  $('<tr></tr>').addClass('total-row');

            if (isDataGridWithFixedHeaderSize()) {
                $footer.addClass('total-row datagrid-old ');
            } else {
                if (_isDashboardContext()) {
                    if (options[DrawOption.PLOT_TYPE] != ChartPlotType.DEFAULT) {
                        const fontSizeValue = DesignFactory.getFontSizeValue();
                        $footer.addClass(`total-row fontsize-${fontSizeValue} `);
                    }
                } else {
                    $footer.addClass('total-row ');
                }
            }

            let styleString = '';
            if (options[DrawOption.PLOT_TYPE] === ChartPlotType.DEFAULT) {
                styleString = getStyleString(options, true);
            }

            let bgColor = UIColor.getGradientColor(AppFactory.getUser().currentThemeColor, 10);
            let textColor = UIColor.textColorWithBackgroundColor(AppFactory.getUser().currentThemeColor);
            if (canApplyAlternatingRowColors(options)) {
                if (!WidgetUtilService.isGroupedColumnPlotType(widget.metadata.draw_options)) {
                    styleString += 'color: ' + textColor + ' !important;';
                    styleString += 'background-color: ' + bgColor  + ' !important;';
                }
            }

            var totalData = {};
            if (options.total_data && !options.total_data.current_data){
                totalData = {"current_data": options.total_data};
            } else{
                totalData = options.total_data;
            }
            var columns = options.aoColumns;
            var helpTitle = gettextCatalog.getString('This total represents an aggregation across all rows based on your widget configuration. These totals can be represented as sums, calculated formulas, most recent values, weighted averages, or even real time requests depending on the source data. If you have questions, please reach out to your support specialists.');
            var helpIcon = '<span data-toggle="tooltip" class="icomoon-help" title="'+ helpTitle +'" data-placement="right"></span>';
            var totalTitle = ReportStudioTemplateDataService.getIsActive() ? '<b>Total </b>' : '<b>Total' + helpIcon + '</b>';
            if (totalData) {
                _.each(columns, function (column, i) {
                    //Add styles for heat map plot type
                    let columnStyles = "";
                    if (WidgetUtilService.isHeatMapPlotType(widget.metadata.draw_options)) {
                        const selectedColumn = options.columns[i];
                        if (selectedColumn.bgColor !== null) {
                            const fontColor = UIColor.textColorWithBackgroundColor(selectedColumn.bgColor);
                            columnStyles =  'background-color:'+selectedColumn.bgColor+' !important;color:'+fontColor+' !important;';
                        }
                    }

                    let cssObject = {};
                    bgColor = UIColor.getGradientColor(AppFactory.getUser().currentThemeColor, 10);
                    if (canApplyAlternatingRowColors(options)) {
                        bgColor = UIColor.getGradientColor(bgColor, 10);
                        textColor = UIColor.textColorWithBackgroundColor(bgColor);
                        cssObject = {
                            'background-color': bgColor,
                            'color': textColor
                        }
                    }
                    if (WidgetUtilService.isGroupedColumnPlotType(widget.metadata.draw_options) && column.className?.includes('grouped-column-#')) {
                        let colorClassPosition = column.className.indexOf('grouped-column-#');
                        bgColor = column.className.substring(colorClassPosition + 15, colorClassPosition + 22);
                        cssObject = {
                            'background-color': bgColor,
                            'color': UIColor.textColorWithBackgroundColor(bgColor)
                        }
                    }
                    if (column.data !== 'control') {
                        var value = '';
                        if (column.format === ColumnFormat.FORMAT_INTEGER
                            || column.format === ColumnFormat.FORMAT_DECIMAL
                            || column.format === ColumnFormat.FORMAT_PERCENT
                            || column.format === ColumnFormat.FORMAT_CURRENCY
                        ) {
                            value = $.fn.formatNumber(totalData.current_data[column.data], column.format, column.precision, false, totalData.current_data, widget.metadata.currency_discrepancy);
                        }
                        else if (column.format === ColumnFormat.FORMAT_TIME) {
                            value = _.isInteger(totalData.current_data[column.data]) ?
                                moment.unix(totalData.current_data[column.data]).utc().format(options.dateFormat.momentFormat) :
                                _.isNull(totalData.current_data[column.data]) ? '-' : totalData.current_data[column.data];
                        }

                        // Add total title if first column
                        if (i === 0) {
                            value = totalTitle +' '+ value;
                            cssObject["word-wrap"] = "inherit";
                            $footer.append('<th style="' + styleString + '' + columnStyles +'">' + value + '</th>').find("th:last").css(cssObject);
                        }else {
                            if (!totalData.prior_data || ![ColumnFormat.FORMAT_INTEGER, ColumnFormat.FORMAT_DECIMAL, ColumnFormat.FORMAT_PERCENT, ColumnFormat.FORMAT_CURRENCY, ColumnFormat.FORMAT_TIME].includes(column.format)){
                                // if prior_data is not available or if column format is not comparable then just append the value since comparison logic cannot be applied on this.
                                $footer.append('<th style="' + styleString + '' + columnStyles + '">' + value + '</th>').find("th:last").css(cssObject);
                            } else {
                                // To compare total_row data
                                var data = {
                                    value: totalData.current_data[column.data],
                                    comparisonValue: totalData.prior_data[column.data]
                                };
                                var columnOptions = {};
                                columnOptions.className = fieldToHash(column.field) + ' ';
                                column.field = column.field || column.data;
                                columnOptions.data = column.field;

                                if (column.format === ColumnFormat.FORMAT_LINK
                                    && column.postprocess_type !== PostProcessType.POSTPROCESS_IFRAME) {
                                    columnOptions.className += 'linked-cell';
                                }

                                if (options.customRenders && options.customRenders[column.field] && !options.isSample) {
                                    columnOptions.render = options.customRenders[column.field];
                                } else {
                                    getFormattedData(column, columnOptions, widget, options, true);
                                }

                                $footer.append('<th class=' + columnOptions.className + '>' +
                                    columnOptions.render(data, 'display') +
                                    '</th>');
                            }
                        }
                    }
                });
            }

            if (showAtBottom) {
                if (options.enableFixedHeader) {
                    // Fix total-row on bottom is deleted after init completes if table is responsive.
                    !$scrollBodyTable.has('tr.total-row').length &&
                    $footer.appendTo($scrollBodyTable.find('tbody'));
                } else {
                    $footer.appendTo($table.find('tbody'));
                }
            }
            else {
                if (options.enableFixedHeader) {
                    $footer.appendTo($scrollHeadTable.find('thead'));
                } else {
                    $footer.appendTo($table.find('thead'));
                }
            }

            // When responsive we need to keep the columns' visiblities in sync
            if (options.responsive !== false) {
                if (settings.responsive && settings.responsive.s) {
                    // Handle visibility on initialization
                    handleResponsiveFooter(settings.responsive.s.current, $footer);
                }

                oTable.on('responsive-resize', function (e, table, columns) {
                    handleResponsiveFooter(columns, $footer);
                });
            }
        }
    }

    /**
     * Helper function to update scroll body div height in order to make scroll work correctly
     * @param widget
     * @param options
     * @param settings
     */
    function updateScrollBodyHeight(widget, options, settings) {
        if (!options.enableFixedHeader) {
            return;
        }
        var $tableWrapper = $(settings.nTableWrapper);
        var $scrollHead = $(settings.nScrollHead);
        var $scrollBody = $(settings.nScrollBody);
        // Old table could be destroyed so we need to use widget id to get the DOM
        var $datagridWidget = angular.element('#widget-' + widget.id).find('.datagrid-display');

        // Get height of container without horizontal scrollbar
        // If datagrid widget cannot be found by widget id, meaning the widget is not in a dashboard (i.e. preview),
        // we just assign bodyHeight with originalBodyHeight since widget height will not change.
        var bodyHeight = $datagridWidget.length ? $datagridWidget[0]['clientHeight'] : options.originalBodyHeight;
        bodyHeight -= $scrollHead.outerHeight();
        if (isPaginating(widget.metadata)) {
            // If pagination is enabled, DOM selector is not consistent for responsive/none-responsive
            // However, there is an observation that
            // paginationHeight = height(dataTables_scroll) - height(dataTables_scrollHead) - height(dataTables_scrollBody)
            var $dataTablesScroll = $tableWrapper.find('div.dataTables_scroll');
            bodyHeight -= $dataTablesScroll.height() - $scrollHead.outerHeight() - $scrollBody.outerHeight();
        }

        $scrollBody.css('height', bodyHeight-2 + 'px');

        // Unset widget-content max-height to allow expansion
        const widgetContent = $tableWrapper.closest('.widget-content')[0];
        if (widgetContent) {
            // max-height defined in design.isotope.less
            $(widgetContent).css('maxHeight', 'none');
        }
    }

    /**
     * Helper function to tell if we need to inlcude show total row
     * @param widget
     * @returns {*|boolean}
     */
    function includeShowTotalRow(widget) {
        var drawOptions = widget.metadata.draw_options;
        return drawOptions[DrawOption.SHOW_TOTAL_ROW] && !drawOptions[DrawOption.PIVOT_GRID] && !hasMetricFilter(widget);
    }

    function showOverflowTruncateMessage() {
        const { currentPage } = ExportBuilderDashboardService.getBuilder();
        const truncateMessageElement = currentPage.elements.find(
          element => element.id === currentPage.overflow_truncate_message_element_id
        );
        if (truncateMessageElement) {
          truncateMessageElement.height = 2.5;
        }
    }

    function removeEmptyPages() {
        const sharedData = ExportBuilderDashboardService.getBuilder();
        const { report, currentPageIndex, previousPageIndex } = sharedData;
        const { pages } = report;

        let pageIndex = currentPageIndex;
        while (pages[pageIndex].metadata.hide_if_empty_widget) {
          pages.splice(pageIndex, 1);
          if (!pages[pageIndex]) {
            pageIndex--;
            break;
          }
          if (currentPageIndex < previousPageIndex) {
            pageIndex--;
          }
        }

        sharedData.currentPageIndex = pageIndex;
        sharedData.currentPage = pages[pageIndex];
        ExportBuilderDashboardService.updatePageIndex();
    }

    /**
     * Maps the visiblity status of the responsive columns and the footer columns
     * @param columns
     */
    function handleResponsiveFooter(columns, $footer) {
        _.each(columns, function(isVisible, index) {
            var column = $footer.find('th')[index];
            isVisible ? $(column).show() : $(column).hide();
        });
    }

    /**
     * Set columns for datatable
     * @param options
     * @param widget
     */
    function setDTColumns(options, widget) {
        let metadata = widget.metadata;
        var columns = metadata.data_columns.selected;
        var dataSource = metadata.data_source;

        options.columns = [];

        /**
         * Debounce the call to resize datagrid
         * @type {Function}
         * @private
         */
        var _callResizeColumns = _.debounce(function() {
            PubSub.emit($EventConstants().UPDATE_COLUMN_WIDTH + widget.id);
        }, 300);

        _.each(columns, function (column, index) {
            if (!column.is_hidden) {
                var columnOptions = {};
                if (isDataGridWithFixedHeaderSize()) {
                    columnOptions.className = fieldToHash(column.field) + ' datagrid-old ';
                } else {
                    if (_isDashboardContext()) {
                        const fontSizeValue = DesignFactory.getFontSizeValue();
                        columnOptions.className = fieldToHash(column.field) + ` fontsize-${fontSizeValue} `;
                    } else {
                        columnOptions.className = fieldToHash(column.field) + ' ';
                    }
                }
                const types = [ColumnFormat.FORMAT_STRING , ColumnFormat.FORMAT_IMAGE];
                if (types.includes(column.format) && column.postprocess_type === PostProcessType.POSTPROCESS_IFRAME) {
                    columnOptions.className += ' image-preview image-preview--flip ';
                }

                // space needed to append more classes below
                columnOptions.orderable = column.is_sortable;
                columnOptions.data = column.field;
                columnOptions.compare_inverted = column.compare_inverted;

                if (WidgetUtilService.isHeatMapPlotType(widget.metadata.draw_options)) {
                    let currentPalate = metadata?.chart_palette || DesignFactory.getCurrentPage().metadata?.chart_palette;
                    if (ReportStudioTemplateDataService.getIsActive() && _.isEmpty(currentPalate)) {
                        currentPalate = ReportStudioTemplateDataService.getReport().metadata?.chart_palette;
                    }
                    columnOptions.bgColor = ChartFactory.getPaletteColor(index, currentPalate);
                }


                if (column.is_primary_key) {
                    columnOptions.className += 'action-cell';
                    //Allow overriding primary key column (edit, delete buttons, etc.)
                    if (!_.isUndefined(options.customRenders) && !_.isUndefined(options.customRenders[column.field])) {
                        columnOptions.render = options.customRenders[column.field];
                    }
                    else {
                        options.numActions = 3;
                        columnOptions.render = function (data, type, full) {
                            // Default action button, can be overridden
                            var href = '#/' + dataSource.type + '/' + data;
                            var html = DataGridRender.getEditButton(full.can_be_edited, href);
                            html += DataGridRender.getDeleteButton(full.can_be_deleted, href, null, full.can_be_deleted_tooltip);
                            if (full.can_be_copied) {
                                html += DataGridRender.getCopyButton(full.can_be_copied, href);
                            }
                            return html;
                        }
                    }
                }
                else if (column.is_primary_name_field && !column.is_primary_date_field && !options.isSample) {
                    // Allow overriding primary name column
                    if (!_.isUndefined(options.customRenders) && !_.isUndefined(options.customRenders[column.field])) {
                        columnOptions.className += 'linked-cell';
                        columnOptions.render = options.customRenders[column.field];
                    }
                    // Force overriding primary name column generically
                    else if (!_.isUndefined(options.customPrimaryNameFieldRender)) {
                        columnOptions.className += 'linked-cell';
                        columnOptions.render = options.customPrimaryNameFieldRender;
                    }
                }
                else {
                    if (column.format === ColumnFormat.FORMAT_LINK
                        && column.postprocess_type !== PostProcessType.POSTPROCESS_IFRAME) {
                        columnOptions.className += 'linked-cell';
                    }

                    if (options.customRenders && options.customRenders[column.field] && !options.isSample) {
                        columnOptions.render = options.customRenders[column.field];
                    } else {
                        getFormattedData(column, columnOptions, widget, options);
                    }
                }

                // Column fixed width
                if (!_.isUndefined(options.columnWidths) && !_.isUndefined(options.columnWidths[column.field])) {
                    columnOptions.sWidth = options.columnWidths[column.field];
                }

                // Store internal enum values from data inside the column to be accessible externally
                if (column.has_set_values) {
                    columnOptions.values = column.values;
                }

                if (WidgetUtilService.isGroupedColumnPlotType(widget.metadata.draw_options)) {
                    if (!_.isEmpty(widget.metadata.user_grouped_columns)) {
                        widget.metadata.user_grouped_columns.forEach(userGroupedColumn => {
                            if (userGroupedColumn.values?.includes(column.field)) {
                                columnOptions.className += " grouped-column-" + userGroupedColumn.color + " ";
                                // return false;
                            }
                        });
                    }
                }

                if (WidgetUtilService.isEmbeddedSparklinesPlotType(widget.metadata.draw_options)) {
                    if (!_.isEmpty(widget.metadata.fields_to_embed_sparklines)) {
                        widget.metadata.fields_to_embed_sparklines.forEach(userEmbedSparklineColumn => {
                            if (userEmbedSparklineColumn.values?.includes(column.field)) {
                                // Ensure columnOptions.sWidth is a string with a valid CSS width value
                                let existingWidth = parseFloat(columnOptions.sWidth) || 0;
                                const isComparisonEnabled = ChartFactory.isComparisonEnabled(widget.metadata) || widget.metadata.compare_to_prior_period;
                                let newWidth = existingWidth + isComparisonEnabled ? 25 : 20; // Increase width by 40%
                                columnOptions.sWidth = newWidth + '%';
                            }
                        });
                    }
                }


                if (column.format === ColumnFormat.FORMAT_STRING) {
                    if (!canApplyWrapText(widget.metadata)) {
                        columnOptions.className += ' datagrid-string-column ';
                    } else {
                        columnOptions.className += ' datagrid-long-string ';
                    }
                }

                columnOptions.precision = column.precision;
                columnOptions.format = column.format;
                columnOptions.title = getTitle(column, options, metadata);
                options.columns.push(columnOptions);
            }
        });

        if (options.responsive !== false) {
            // Add responsive row toggler column
            options.columns.push({
                title: gettextCatalog.getString('Show More'),
                className: 'control',
                data: 'control',
                orderable: false
            });
        }

        if (options.numActions > 0) {
            //If more than one action grow by 34 for each action, else 55 by default
            var width =  options.numActions > 1 ? options.numActions * 34 : 55;
            var target = _.findIndex(options.columns, {className: 'action-cell'});
            options.columnDefs = [
                { orderable: false, targets: target, width: width }
            ];
        }
    }

    /**
     * Helper function to get 32bit column field hash
     * @param {*} field
     * @returns
     */
    function fieldToHash(field) {
        let hash = 0;
        if (!field || field.length == 0) return hash;
        for (let i = 0; i < field.length; i++) {
            const char = field.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return hash;
    }

    /**
     * Helper function to get column title
     * @param column
     * @param options
     * @param metadata
     * @returns {string}
     */
    function getTitle(column, options, metadata) {
        var label = column.label;
        if (!_.isEmpty(column.tooltip) && AppFactory.getUser().isAdmin())
        {
            label = label+`<span class='ml5 icomoon-help has-tooltip' title="${column.tooltip.replace('"', "'").replace('\"', "'")}" data-placement='bottom' style='vertical-align: middle;'></span>`;
        }

        var sortNum = getSortNum(column, metadata);
        if (!options.multiSorting || sortNum === 0) {
            return label;
        }

        return label + ' <div class="sort-label">' + sortNum + '</div>';
    }

    /**
     * Helper function to get sort number
     * @param column
     * @param metadata
     * @returns {number}
     */
    function getSortNum(column, metadata) {
        return _.indexOf(metadata.sort_by, column.field) + 1;
    }

    /**
     * Helper function to tell us if we need to enable fixed header for data grid
     * @param widget
     * @param data
     */
    function setFixedHeader(widget, data) {
        let metadata = widget.metadata;
        var layoutMetadata = DesignFactory.getCurrentLayout().metadata;
        return !_.isEmpty(layoutMetadata)
            && layoutMetadata.is_packed // current dashboard is an NWA dashboard
            && !DesignFactory.getIsExportingPage()
            && !widget.is_export // not in export builder
            && !metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA]
            && (isPaginating(metadata) // Always enable fixed header if pagination is turned on since datagrid is created before data is returned
                || !_.isEmpty(data));
    }

    /**
     * Set required CSS classes from draw options
     * @param columnOptions
     * @param metadata
     * @private
     */
    function _setCSSImageColumnOptions(columnOptions, metadata) {
        // Force full size display of images
        if (metadata.draw_options[DrawOption.GRID_FULL_IMAGE_SIZE]) {
            columnOptions.className += ' full';
        }

        if (metadata.draw_options[DrawOption.GRID_PREVIEW_AS_IFRAME]) {
            // nothing for now
        }

        if (!_isDashboardContext() && metadata.draw_options[DrawOption.GRID_MAX_HEIGHT_IMAGE]) {
            columnOptions.className += ` full max-height-image-${metadata.draw_options[DrawOption.GRID_MAX_HEIGHT_IMAGE]} `
        }

        if (!metadata.draw_options[DrawOption.GRID_FULL_IMAGE_SIZE]
            && !metadata.draw_options[DrawOption.GRID_FULL_IMAGE_SIZE]) {
            columnOptions.className += ' image-preview--aspect-fit';
        }
    }

    /**
     * Formats the date value based on time grouping type and if it needs moment formatting
     * @param dateString
     * @param options
     * @param metadata
     * @param row
     * @param column
     * @param isComparison
     * @returns {string}
     */
    function formatDateValue(dateString, options, metadata, row, column, isComparison) {
        var formattedDate;
        var hasTimeUnitGrouping = metadata.time_grouping === TimeGrouping.GROUPING_MONTH_OF_YEAR
            || metadata.time_grouping === TimeGrouping.GROUPING_DAY_OF_WEEK
            || metadata.time_grouping === TimeGrouping.GROUPING_DAY_OF_MONTH
            || metadata.time_grouping === TimeGrouping.GROUPING_HOUR_OF_DAY
            || metadata.time_grouping === TimeGrouping.GROUPING_YEARLY
            || metadata.time_grouping === TimeGrouping.GROUPING_HOURLY
            || metadata.time_grouping === TimeGrouping.GROUPING_HOURLY_AUDIENCE
            || metadata.time_grouping === TimeGrouping.GROUPING_HOURLY_ADVERTISER
            || metadata.time_grouping === TimeGrouping.GROUPING_DYNAMIC;

        const timeGrouping = metadata.time_grouping === TimeGrouping.GROUPING_DYNAMIC
          ? row.timegroup
          : metadata.time_grouping;

        // Sample data doesn't have 'formatted' field
        if (hasTimeUnitGrouping && !options.isSample) {
            formattedDate = isComparison
                ? row.comparison_data['formatted_' + column.field]
                : row['formatted_' + column.field];
        } else {
            formattedDate = moment.unix(dateString).utc().format(WidgetFactory.getDateFormats(timeGrouping).momentFormat);
            if (options.isSample && metadata.time_grouping === TimeGrouping.GROUPING_DYNAMIC) {
                formattedDate = moment.unix(dateString).utc().format(MomentDateFormat.ISO)
            }
        }

        if (!options.isSample) {
            switch (timeGrouping) {
                case TimeGrouping.GROUPING_DAY_OF_MONTH:
                    // This will allow us to convert a 1 to a 1st pretty date day display
                    // If it comes from sample, it is already formatted
                    formattedDate = moment('Jan ' + formattedDate).format(options.dateFormat.momentFormat);
                    break;
                case TimeGrouping.GROUPING_WEEKLY:
                    // Always use formatted_log_date for by week grouping on front end
                    // since formatting on backend is too complex because of snowflake (TA-19753)
                    formattedDate = isComparison
                        ? moment(row.comparison_data['formatted_' + column.field]).format(options.dateFormat.momentFormat)
                        : moment(row['formatted_' + column.field]).format(options.dateFormat.momentFormat);
                    break;
                default:
                    break;
            }
        }

        return _.isInteger(dateString)
            ? formattedDate
            : !_.isEmpty(dateString)
                ? dateString : '-';
    }


    /**
     * return data according with column format
     * @param column
     * @param columnOptions
     * @param widget
     * @param options
     * @returns {*}
     */
    function getFormattedData(column, columnOptions, widget, options, isTotalRow) {
        let metadata = widget.metadata;

        /**
         * Debounce the call to resize datagrid
         * @type {Function}
         * @private
         */
        var _callResizeColumns = _.debounce(function() {
            PubSub.emit($EventConstants().UPDATE_COLUMN_WIDTH + widget.id);
        }, 300);

        switch (column.format) {

            case ColumnFormat.FORMAT_BOOLEAN:
                columnOptions.className += 'text-center';
                columnOptions.render = function (data) {
                    return formatComparison(data, column, options, widget, function (unformattedData) {
                        // BaseDAO likes to format bools as yes or no
                        return (_.isNull(unformattedData) || _.isUndefined(unformattedData)) ? '-' : _.lowerCase(unformattedData);
                    });
                };
                break;

            case ColumnFormat.FORMAT_INTEGER:
            case ColumnFormat.FORMAT_DECIMAL:
            case ColumnFormat.FORMAT_PERCENT:
            case ColumnFormat.FORMAT_CURRENCY:
                // @TODO: skip formatcomparison if options.isComparing
                if (options.isComparing) {
                    columnOptions.sType = 'comparison-value';
                }
                columnOptions.className += 'text-right';
                columnOptions.render = function (data, type, row) {
                       return formatComparison(data, column, options, widget, function (unformattedData, isPriorPeriod) {
                           if (unformattedData === '-'  || type !== 'display'){
                               return unformattedData;
                           } else {
                               if (shouldAddPercentMetric(metadata, column, widget, options) && !isTotalRow) {
                                   const columnTotalValue = getColumnTotalValue(options.total_data, column.field, widget.has_live_integration, isPriorPeriod);
                                   return $.fn.formatNumber(unformattedData, column.format, column.precision, false, row, widget.metadata.currency_discrepancy, widget.metadata.show_currency) + ' - ' + getPercentOfTotal(unformattedData, columnTotalValue, row);
                               } else {
                                   return $.fn.formatNumber(unformattedData, column.format, column.precision, false, row, widget.metadata.currency_discrepancy, widget.metadata.show_currency);
                               }
                           }
                       });
                };
                break;

            case ColumnFormat.FORMAT_TIME:
                // for columns that have a time format hhh-mm-ss and will use jquery datatable sorting algorithmn
                if (!options.isComparing) {
                    columnOptions.sType = 'number';
                }
                // Time is already formatted by the backend
                columnOptions.className += 'text-right';
                columnOptions.render = function (data) {
                    return formatComparison(data, column, options, widget, function (unformattedData) {
                        return unformattedData;
                    });
                };
                break;

            case ColumnFormat.FORMAT_DATE:
            case ColumnFormat.FORMAT_DATETIME:
                columnOptions.className += 'text-left';
                if (!options.isComparing) {
                    columnOptions.sType = 'date';
                }
                columnOptions.render = function (data, type, row) {
                    if (column.is_primary_date_field
                        && options.dateFormat
                        && data) {
                        return formatComparison(data, column, options, widget, function (unformattedData, isComparison) {
                            return formatDateValue(unformattedData, options, metadata, row, column, isComparison);
                        });
                    } else if (row['formatted_' + column.field] || (row.comparison_data && row.comparison_data['formatted_' + column.field])) {
                        // Default to backend formatting
                        return row['formatted_' + column.field] || (row.comparison_data && row.comparison_data['formatted_' + column.field]);
                    } else {
                        return row[column.field];
                    }
                };
                break;

            case ColumnFormat.FORMAT_PHONE_NUMBER:
                columnOptions.render = function (data, type, row) {
                    if (row['formatted_' + column.field]) {
                        return row['formatted_' + column.field];
                    }
                    return data;
                };
                break;

            case ColumnFormat.FORMAT_AUDIO:
                columnOptions.className += 'type-audio';
                break;

            case ColumnFormat.FORMAT_CLICK_TO_VIEW_LINK:
                columnOptions.className += 'type-clicktoview-link';
                break;

            case ColumnFormat.FORMAT_THUMBNAIL:
                columnOptions.className += 'type-thumbnail';
                break;

            case ColumnFormat.FORMAT_PREVIEW:
                columnOptions.className += 'type-preview';
                break;

            case ColumnFormat.FORMAT_OBJECT:
                columnOptions.render = function (data) {
                    return formatComparison(data, column, options, widget, function (unformattedData) {
                        return unformattedData.value
                    });
                };
                break;

            case ColumnFormat.FORMAT_STRING:
                if (column.postprocess_type === PostProcessType.POSTPROCESS_URL_IMAGE) {
                    columnOptions.className += 'image-preview';

                    _setCSSImageColumnOptions(columnOptions, metadata);

                    columnOptions.render = function (data, type, row, meta) {
                        // Don't do any rendering if we are displaying sample data
                        if (options.isSample) {
                            return data;
                        }
                        var img = new Image();
                        img.className = 'image-preview-' + metadata.id + '-' + meta.row;
                        img.onload = function () {
                            // Set the css class based on the image dimensions. If the image is
                            // almost a square set the class to square.
                            var longest = (this.width > this.height) ? this.width : this.height;
                            var buffer = 0.1;
                            var className = 'square';
                            if (Math.abs(this.width - this.height) > longest * buffer) {
                                if (this.width > this.height) {
                                    className = 'landscape';
                                } else {
                                    className = 'portrait';
                                }
                            }

                            $('.' + img.className).addClass(className);
                        };
                        img.src = data;
                        return img.outerHTML;
                    }
                } else {
                    columnOptions.render = function (data, type, row) {
                        // If there are set values for a column, we will use  {field}_display text display name of the value
                        if (column.has_set_values) {
                            data = row[column.field + '_display'];
                        }
                        return data || '';
                    }
                }
                break;

            case ColumnFormat.FORMAT_CALLBACK:
            case ColumnFormat.FORMAT_IMAGE:
                if (column.postprocess_type === PostProcessType.POSTPROCESS_IFRAME) {
                    columnOptions.className += 'image-preview text-left';

                    _setCSSImageColumnOptions(columnOptions, metadata);

                    if (setFixedHeader(widget)) {
                        columnOptions.render = function (data, type, row) {
                            $(data).one('load', function () {
                                _callResizeColumns();
                            });

                            return data;
                        }
                    }
                }
                break;

            case ColumnFormat.FORMAT_ENUM:
                columnOptions.render = function (data, type, row) {
                    // If there are set values for a column, we will use  {field}_display text display name of the value
                    if (column.has_set_values) {
                        data = row[column.field + '_display'];
                    }
                    return data || '';
                };
                break;
        }

        if (!isClassicPlotType(options) && !_.isNull(options.grid_theme) && options.grid_theme === true) {
            columnOptions.className += ' show-gridlines';
        }
    }

    /**
     *
     * @param columnValue
     * @param totalValue
     * @returns {*}
     */
    function getPercentOfTotal(columnValue, totalValue, row) {
        return (totalValue && parseInt(totalValue) !== 0 && columnValue && parseInt(columnValue) !== 0) ? $.fn.formatNumber((parseInt(columnValue) / parseInt(totalValue)) * 100, ColumnFormat.FORMAT_PERCENT, 2, false, row) : 0 + '%';
    }

    /**
     * Live calls support total row for both Current period and Prior period in the case of comparison
     * but Stored calls support total row for Current period only.
     * So, this method returns the total value of the columnField from totalData depending on the use case.
     *
     * @param totalData
     * @param columnField
     * @param hasLiveIntegration
     * @param isPriorPeriod
     * @returns {*}
     */
    function getColumnTotalValue(totalData, columnField, hasLiveIntegration, isPriorPeriod) {
        return hasLiveIntegration
            ? (isPriorPeriod ? totalData.prior_data[columnField] : totalData.current_data[columnField])
            : totalData[columnField]
    }

    /**
     *
     * @param metadata
     * @param column
     * @param widget
     * @param options
     * @returns {*|boolean}
     */
    function shouldAddPercentMetric(metadata, column, widget, options) {
        return metadata.draw_options[DrawOption.PERCENT_OF_TOTAL] && column.format === ColumnFormat.FORMAT_INTEGER && widget.type === WidgetType.DATAGRID && options.total_data;
    }

    /**
     * If the innerData value is an object, it is a comparison, and this function will format it as so
     * If the innerData is not an object, it will return the formatted value
     * @param innerData
     * @param column
     * @param options
     * @param widget
     * @param renderCallback
     * @returns {*}
     */
    function formatComparison(innerData, column, options, widget, renderCallback) {
        if (_.isUndefined(innerData)) {
            return '';
        }
        if (_.isObject(innerData)) {
            // Datagrid will display '-' instead of 'null'. Looks much cleaner.
            innerData.value = _.isNull(innerData.value) ? '-' : innerData.value;

            if (!innerData.comparisonValue || _.isNull(innerData.comparisonValue)) {
                if (column.format === ColumnFormat.FORMAT_DATETIME) {
                    return '<p class="value-container flex">' +
                        // order by unix
                        '<span class="value hide">' + innerData.value +  '</span>' +
                        renderCallback(innerData.value) + '</p>';
                }
                const color = getColor(widget.metadata.draw_options);
                return '<p class="value-container flex flex-end"> <span class="value" style="color:'+ color + '">' + renderCallback(innerData.value) + '</span></p>';
            }

            // noComparisonValue is used to tell if there is any comparison data to display, otherwise we will display a '-'
            var noComparisonValue = (innerData.value == 0 || _.isNull(innerData.value))|| (innerData.comparisonValue == 0  || _.isNull(innerData.comparisonValue));

            // delta is the difference between comparison value, and current value. Will not be used to display.
            var delta = WidgetFactory.getComparisonDeltaValue(column, innerData);
            var value = Math.abs(delta) && !noComparisonValue ? Math.round(Math.abs(delta)) : 0;
            if (value > 0) {
                innerData.deltaDisplay = Globalize.format(value, 'n0');
                if (!column.compare_as_value) {
                    innerData.deltaDisplay += '%';
                }
            } else {
                innerData.deltaDisplay = '-';
            }

            // plusOrMinus is used to determine the class we will add to the delta percentage, which will give the delta percentage a green or red color.
            if (resolveDrawOptionValue(DrawOption.DEFAULT_COMPARISON_ARROW_COLOR, widget)) {
                let direction = delta;
                if (column.compare_inverted) {
                    direction = -direction;
                }

                if(('comparison_overwrite' in widget.metadata) && (widget.metadata.comparison_overwrite.includes(column.field) || (isPivotingGrid(widget.metadata) && widget.metadata.comparison_overwrite.includes(column.data)))) {
                    direction = -direction;
                }
                if (direction > 0 && !noComparisonValue) {
                    innerData.plusOrMinus = 'positive';
                }
                else if (direction < 0 && !noComparisonValue) {
                    innerData.plusOrMinus = 'negative';
                }
                else {
                    innerData.plusOrMinus = 'neutral';
                }
                if (column.is_ranking_metric && !noComparisonValue) {
                    innerData.plusOrMinus = direction > 0 ? 'negative' : 'positive';
                }
            }
            else {
                innerData.plusOrMinus = 'generic-comparison';
            }
            let alternateRowStyles = '';
            let whitePatch = '';
            if(options.plot_type !== ChartPlotType.DEFAULT && !isClassicPlotType(options)){
                whitePatch = ' white-br-patch';
            }

            if (canApplyAlternatingRowColors(options) && !AppFactory.getUser().showDefaultComparisonArrowColor) {
                alternateRowStyles = 'color:' + UIColor.textColorWithBackgroundColor(AppFactory.getUser().currentThemeColor) + '!important;';
            }

            var html = '';

            // If not on server side, we need to order by CURRENT PERIOD value on front end
            if (!options.serverSide) {
                if (column.format === ColumnFormat.FORMAT_DATETIME) {
                    // Order by unix
                    html += '<span class="value hide">' + innerData.value +  '</span>';
                }
                else {
                    html += '<span class="value hide">' + '[comparison]' + innerData.value + '[comparison]' + '</span>';
                }
            }

            html += '<p class="value-container">';
            var hideDeltaClass = resolveDrawOptionValue(DrawOption.SHOW_COMPARISON_ARROWS, widget) ? '' : 'hidden ';
            if (column.format != ColumnFormat.FORMAT_DATETIME) {
                html += '<span class="delta '+ hideDeltaClass + innerData.plusOrMinus + whitePatch +'" style="' + alternateRowStyles + '">';

                // If there is a difference between current and comparison values, then we will display an up or down caret
                if (Math.abs(delta) && !noComparisonValue) {
                    let caretClass = delta > 0 ? 'icon-caret-up' : 'icon-caret-down';
                    if (column.is_ranking_metric) {
                        caretClass = delta > 0 ? 'icon-caret-down' : 'icon-caret-up';
                    }

                    // when exporting for Report Studio, embedded fonts break, so we use svg
                    if (DesignFactory.getIsExportingPage() && ReportStudioTemplateDataService.getIsActive()) {
                        html += '<svg class="delta-icon mr2 ' + caretClass + '">' +
                            '<use xlink:href="#' + caretClass + '"></use>\n' +
                            '</svg>'
                    } else {
                        html += '<i class="' + caretClass + ' mr2"></i>';
                    }
                }
                html += innerData.deltaDisplay + '</span>';
            }

            html += '<span class="text-right inline-block">';

            if ([ChartPlotType.DEFAULT, ChartPlotType.CLASSIC, ChartPlotType.EMBEDDED_SPARKLINES].includes(options.plot_type)) {
                let textColor = options.grid_font_properties.text_color;
                // If the text color is not defined, use a default one suitable for the current theme type
                if (!textColor) {
                    textColor = AppFactory.getUser().themeType === UserThemes.TYPE_DARK ? '#ffffff' : '#737c84';
                }
                let comparisonColorValue = ChartFactory.hexToRGBA(textColor, 0.7);
                let styleString = getStyleString(options);
                let comparisonStyleString = getStyleString(options, false, true);

                if (options.grid_font_properties.text_color && ([ChartPlotType.DEFAULT, ChartPlotType.EMBEDDED_SPARKLINES].includes(options.plot_type))) {
                    if (comparisonStyleString.includes(textColor)) {
                        comparisonStyleString.replace(textColor, comparisonColorValue);
                    }
                } else {
                    if (AppFactory.getUser().themeType === UserThemes.TYPE_DARK && !DesignFactory.getIsExportingPage()) {
                        styleString += "color:#ffffff";
                        comparisonStyleString += "color:" + ChartFactory.hexToRGBA("#ffffff", 0.7);
                    } else {
                        if (DesignFactory.getIsExportingPage()) {
                            styleString += "color:#000000";
                            comparisonStyleString += "color:" + ChartFactory.hexToRGBA("#737c84", 0.7) + ";";
                        }
                    }
                }
                html += '<span class="value" style="' + styleString + '">' + renderCallback(innerData.value) + '</span>';
                html += '<span class="value comparison'+ whitePatch + '" style="' + comparisonStyleString + '">' + renderCallback(innerData.comparisonValue, true) + '</span>';
            } else {
                html += '<span class="value">' + renderCallback(innerData.value) + '</span>';
                html += '<span class="value comparison'+ whitePatch + '">' + renderCallback(innerData.comparisonValue, true) + '</span>';
            }

            html += '</span>';
            html += '</p>';

            return html;
        }
        // Data tables can not render 'undefined', null needs to be returned instead.
        return renderCallback(innerData);
    }

    /**
     * Sets the record entries display into a badge (ex: 1 to 20 of 40 entries)
     * @param options
     * @param settings
     * @param metadata
     */
    function setRecordsInfoBadge(options, settings, metadata) {
        var $elm = options.recordsInfoBadge;
        var start = settings._iDisplayStart + 1;
        var end = settings.fnDisplayEnd();
        var total = settings.fnRecordsDisplay();

        if (options.isSample) {
            total = end = _getDisplayLength(metadata);
        }

        $elm.find('span.no-items').hide();
        $elm.find('span.has-items').hide();

        if (total == 0) {
            $elm.find('span.no-items').show();
        }
        else if ($elm.length && total) {
            $elm.find('span.has-items').show();
            $elm.find('span.start').html(start);
            $elm.find('span.end').html(end);
            $elm.find('span.total').html(total);
        }
        else {
            $elm.empty();
        }
    }

    /**
     * @private
     * Will resolve the appropriate draw options based on the inheritance tree (widget - layout - instance)
     * @param key
     * @param widget
     * @returns {*|null}
     */
    function resolveDrawOptionValue(key, widget) {
        return DrawOptionFactory.resolveDrawOptionValue(key, widget, widget.metadata.builder);
    }

    /**
     *
     * @param drawOptions
     * @returns {string}
     */
    function getColor(drawOptions) {
        if (drawOptions.grid_font_properties && drawOptions.grid_font_properties.text_color && drawOptions.plot_type === ChartPlotType.DEFAULT) {
            return drawOptions.grid_font_properties.text_color;
        }

        switch (drawOptions.plot_type) {
            case ChartPlotType.CLASSIC:
            case ChartPlotType.HEAT_MAP:
            case ChartPlotType.CONDITIONAL_MAP:
            case ChartPlotType.GROUPED_COLUMN:
                return '#000000';
            case ChartPlotType.DEFAULT:
                return AppFactory.getUser().themeType === UserThemes.TYPE_DARK ? '#ffffff' : '#000000';
        }
        return '#737c84';
    }

    /**
     *  PubSub emit to adjust columns
     */
    function updateAllScrollBodyHeight() {
        $timeout(function() {
            PubSub.emit($EventConstants().UPDATE_ALL_SCROLL_BODY_HEIGHT);
        }, 0, false);
    }

    /**
     *  PubSub emit to update scroll body
     */
    function updateScrollBody(id) {
        PubSub.emit($EventConstants().UPDATE_SCROLL_BODY_HEIGHT + id);
    }

    /**
     * returns the computed style string
     * @param options
     * @param isTotalRow
     * @param isComparisonStyleString
     * @returns {string}
     */
    function getStyleString(options, isTotalRow = false, isComparisonStyleString = false) {
        let styleString = '';

        if ([ChartPlotType.DEFAULT, ChartPlotType.EMBEDDED_SPARKLINES].includes(options[DrawOption.PLOT_TYPE])) {

                styleString += 'font-size: ' + options[DrawOption.GRID_FONT_SIZE] + 'px;';

            if (options[DrawOption.GRID_FONT_PROPERTIES].bold) {
                styleString += 'font-weight: 900;';
            }
            if (options[DrawOption.GRID_FONT_PROPERTIES].italic) {
                styleString += 'font-style: italic;';
            }
            if (options[DrawOption.GRID_FONT_PROPERTIES].underline) {
                styleString += 'text-decoration: underline';

                if (options[DrawOption.GRID_FONT_PROPERTIES].strikethrough) {
                    styleString += ' line-through';
                }
                styleString += ';';
            } else if (options[DrawOption.GRID_FONT_PROPERTIES].strikethrough) {
                styleString += 'text-decoration: line-through;';
            }

            if (canApplyAlternatingRowColors(options) && !WidgetUtilService.isGroupedColumnPlotType(options)) {
                styleString += 'color: ' + UIColor.textColorWithBackgroundColor(AppFactory.getUser().currentThemeColor) + ' !important;';
            } else if (options[DrawOption.GRID_FONT_PROPERTIES].text_color) {
                if (!isComparisonStyleString) {
                    styleString += "color: " + options[DrawOption.GRID_FONT_PROPERTIES].text_color + ";";
                } else {
                    styleString += "color: " + ChartFactory.hexToRGBA(options[DrawOption.GRID_FONT_PROPERTIES].text_color, 0.7) + ";";
                }
            }
        }

        return styleString;
    }

    function fixGridLongStringStyles(widgetElement) {
        widgetElement?.childNodes?.forEach((rowElement, rowElementIndex) => {
            rowElement?.childNodes?.forEach((columnElement, index) => {
                let outerHtml = columnElement.outerHTML;
                let hasStyle = outerHtml?.includes('style=');
                const hasComparison = outerHtml?.includes('comparison');
                let styleString = 'style="max-width:122px;';
                let updatedOuterHtml = '';
                if (hasStyle && hasComparison) {
                    if (outerHtml.indexOf('style=') > outerHtml.indexOf('<p class="value-container">')) {
                        hasStyle = false;
                    }
                }
                if (widgetElement?.localName !== 'tfoot' && columnElement?.className?.includes('datagrid-long-string')) {
                    if (hasStyle) {
                        updatedOuterHtml = outerHtml?.replace(outerHtml.substring(outerHtml.indexOf('style='), outerHtml.indexOf('style=') + 7), styleString);
                    } else {
                        styleString += '" class=';
                        updatedOuterHtml = outerHtml?.replace(outerHtml.substring(outerHtml.indexOf('class='), outerHtml.indexOf('class=') + 6), styleString);
                    }
                    columnElement.outerHTML = updatedOuterHtml;
                }
            });
        });
    }

        /**
     * Helper function to set the required styles on the datagrid widget
     * @param widgetElement
     * @param isWrapText
     * @param drawOptions
     * @param widgetId
     */
    function applyGridStyles(widgetElement, isWrapText = false, drawOptions, widgetId) {
        widgetElement?.childNodes?.forEach((rowElement, rowElementIndex) => {
            rowElement?.childNodes?.forEach((columnElement, index) => {
                if ($(columnElement).hasClass('control')) {
                    return;
                }
                let styleString = '';
                let outerHtml = columnElement.outerHTML;
                let updatedOuterHtml = '';
                let hasStyle = columnElement.hasAttribute('style');
                const hasComparison = outerHtml?.includes('comparison');
                if (hasStyle && hasComparison) {
                    if (outerHtml.indexOf('style=') > outerHtml.indexOf('<p class="value-container">')) {
                        hasStyle = false;
                    }
                }
                let updateStyleString = false;
                if (isWrapText && columnElement?.className?.includes('datagrid-string-column')) {
                    let desiredMaxWidth = (columnElement.clientWidth - 30);
                    // 122 is min width by datatable
                    if (desiredMaxWidth > 122) {
                        desiredMaxWidth = 122;
                    }
                    desiredMaxWidth = desiredMaxWidth + 'px'
                    if (hasStyle) {
                        styleString = 'style="max-width:' + desiredMaxWidth + ';white-space:nowrap;';
                    } else {
                        styleString = 'style="max-width:' + desiredMaxWidth + ';white-space:nowrap;"';
                    }
                    updateStyleString = true;
                }
                if (!isWrapText) {
                    const currentUser = AppFactory.getUser();
                    let groupedColumnBgColor;
                    let textColor;
                    let gradientColorValue = (rowElementIndex % 2) === 0 ? 50 : 30;
                    if (widgetId || (DesignFactory.getIsExportingPage() && widgetElement?.localName === 'thead')) {
                        gradientColorValue = 10;
                    }
                    if (columnElement?.className?.includes('grouped-column-#')) {
                        let colorClassPosition = columnElement.className.indexOf('grouped-column-#');
                        groupedColumnBgColor = columnElement.className.substring(colorClassPosition + 15, colorClassPosition + 22);
                        if (drawOptions[DrawOption.GRID_ALTERNATING_ROW_COLOR]) {
                            groupedColumnBgColor = UIColor.getGradientColor(groupedColumnBgColor,gradientColorValue);
                        }
                        textColor = UIColor.textColorWithBackgroundColor(groupedColumnBgColor);
                    } else if (drawOptions[DrawOption.GRID_ALTERNATING_ROW_COLOR]) {
                        groupedColumnBgColor = UIColor.getGradientColor(currentUser.currentThemeColor,gradientColorValue);
                        textColor = UIColor.textColorWithBackgroundColor(groupedColumnBgColor);
                    }

                    if (groupedColumnBgColor && textColor) {
                        styleString = 'style="background-color:' + groupedColumnBgColor + '!important;color:' + textColor + '!important;';
                    }
                    if (widgetId) {
                        const cssObject = {
                            'background-color': groupedColumnBgColor,
                            'color': textColor
                        };
                        if (ReportStudioTemplateDataService.getIsActive()) {
                            $('#widgetID-' + widgetId + ' .dataTable thead:first tr').find('th:nth-child(' + (index+1) + ')').css(cssObject);
                        } else {
                            $('#widget-' + widgetId + ' .dataTables_scrollHead .dataTables_scrollHeadInner .dataTable thead:first tr').find('th:nth-child(' + (index+1) + ')').css(cssObject);
                        }
                    } else {
                        updateStyleString = true;
                    }
                }
                if (updateStyleString && !widgetElement?.localName !== 'tfoot' && styleString) {
                    if (hasStyle) {
                        updatedOuterHtml = outerHtml?.replace(outerHtml.substring(outerHtml.indexOf('style='), outerHtml.indexOf('style=') + 7), styleString);
                    } else {
                        styleString += '" class=';
                        updatedOuterHtml = outerHtml?.replace(outerHtml.substring(outerHtml.indexOf('class='), outerHtml.indexOf('class=') + 6), styleString);
                    }
                    columnElement.outerHTML = updatedOuterHtml;
                }
            });
        });
    }

    /**
     * Helper function to add a new row for Grouped column
     * @param widget
     */
    function prependGroupedColumnHeader(widget) {
        if (WidgetUtilService.isGroupedColumnPlotType(widget.metadata.draw_options)) {
            if (!_.isEmpty(widget.metadata.user_grouped_columns)) {
                let tableHeader;
                const widgetIdSelector = ReportStudioTemplateDataService.getIsActive() ? '#widgetID-' + widget.id : '#widget-' + widget.id;
                if (!DesignFactory.getIsExportingPage()) {
                    if (ReportStudioTemplateDataService.getIsActive()) {
                        tableHeader = angular.element(widgetIdSelector + ' .dataTable')[0]?.firstElementChild;
                    } else {
                        tableHeader = angular.element(widgetIdSelector + ' .dataTables_scrollHead .dataTables_scrollHeadInner')[0]?.firstElementChild?.firstElementChild;
                    }
                } else {
                    tableHeader = angular.element(widgetIdSelector + ' .datagrid-display .widget-display .dataTable')[0]?.firstElementChild;
                }
                if (tableHeader?.firstElementChild?.className?.includes('user-grouped-column-header')) {
                    tableHeader.deleteRow(0);
                }
                let row = tableHeader?.insertRow(0);
                angular.element(row).addClass('user-grouped-column-header');
                let cellIndex = 0;
                let isExistUserGroupedName = [];
                widget.metadata.data_columns.selected.forEach((selectedColumn, index) => {
                    let styleString = '';
                    const selectedColumnField = selectedColumn.id || selectedColumn.groupby_id_field;
                    const isUserGroupedSelectedColumn =
                      widget.metadata.user_grouped_columns.find((column) => {
                        return (
                          !_.isNull(column.values) &&
                          !_.isUndefined(column.values) &&
                          column.values.includes(selectedColumnField)
                        );
                      });

                    if (isUserGroupedSelectedColumn && !isExistUserGroupedName.includes(isUserGroupedSelectedColumn.name)) {
                        isExistUserGroupedName.push(isUserGroupedSelectedColumn.name);
                        let hash = '';
                        let bgColor = '';
                        let color = '';
                        isUserGroupedSelectedColumn.values.every(field => {
                            hash = fieldToHash(field);
                            if (hash) {
                                if (!bgColor) {
                                    bgColor = $(`${widgetIdSelector} .${hash}`).css('background-color');
                                    styleString += "background-color:" + bgColor + ";";
                                }
                                if (!color) {
                                    color = $(`${widgetIdSelector} .${hash}`).css('color');
                                    styleString += "color:" + color + ";";
                                }
                                return false;
                            }
                        });
                        if (row) {
                            let newColumn = row.insertCell(cellIndex);
                            let colSpanLength = isUserGroupedSelectedColumn.values.length || 0;
                            newColumn.outerHTML = '<th class="datagrid-old text-center" colspan="' + colSpanLength + '" style="' + styleString + '">' + isUserGroupedSelectedColumn.name + '</th>';
                            cellIndex++;
                        }
                    } else if (!isUserGroupedSelectedColumn){
                        if (row) {
                            let newColumn = row.insertCell(cellIndex);
                            newColumn.outerHTML = '<th></th>';
                            cellIndex++;
                        }
                    }
                });
            }
        }
    }

    /**
     * Helper function to return if wrap text can be applied or not
     * @param metadata
     * @returns {boolean|*}
     */
    function canApplyWrapText(metadata) {
        return isClassicPlotType(metadata.draw_options) || metadata.draw_options[DrawOption.WRAP_TEXT_NAME];
    }

    /**
     * Helper function to return if fix for long grid styles can be applied or not
     * @param metadata
     * @returns {boolean}
     */
    function canFixLongGridStyles(metadata) {
        return !isClassicPlotType(metadata.draw_options) && !metadata.draw_options[DrawOption.GRID_IS_RESPONSIVE];
    }

    /**
     * Helper function to return if a datagrid widget is of Classic plot type or not
     * @param drawOptions
     * @returns {boolean}
     */
    function isClassicPlotType(drawOptions) {
        return drawOptions[DrawOption.PLOT_TYPE] === ChartPlotType.CLASSIC;
    }

    /**
     * Helper function to return if a alternating row colors can be applied on a widget or not
     * @param drawOptions
     * @returns {*|boolean}
     */
    function canApplyAlternatingRowColors(drawOptions) {
        return drawOptions[DrawOption.GRID_ALTERNATING_ROW_COLOR] &&
            !isClassicPlotType(drawOptions) &&
            !WidgetUtilService.isHeatMapPlotType(drawOptions) &&
            !WidgetUtilService.isConditionalPlotType(drawOptions) &&
            !WidgetUtilService.isEmbeddedSparklinesPlotType(drawOptions);
    }
}

/**
 * Helper datagrid methods for rendering cell items & updating table values
 *
 * @ngInject
 */
function DataGridRender(
    $rootScope,
    $http,
    $timeout,
    DataGridModelFactory,
    LayoutFactory,
    UIFactory,
    gettextCatalog
) {
    return {
        updateDataTableRow: updateDataTableRow,
        refreshDataTable: refreshDataTable,
        getActionLink: getActionLink,
        getActionButton: getActionButton,
        getEditButton: getEditButton,
        getEditDetailsButton: getEditDetailsButton,
        getDeleteButton: getDeleteButton,
        getCopyButton: getCopyButton,
        bindDeleteRowAction: bindDeleteRowAction,
        bindCopyRowAction: bindCopyRowAction
    };

    /**
     * Update table from an action inside the datatable
     * @param model
     * @param table
     * @param row
     * @param primaryKeyField
     */
    function updateDataTableRow(model, table, row, primaryKeyField) {
        if (table.settings()[0].oAjaxData) {
            row.data(model).draw(false);
        } else {
            var primaryKeyId = model[primaryKeyField];
            var newData = _.map(table.data(), function(datum) {
                return datum[primaryKeyField] === primaryKeyId ? _mapModelToRow(model, datum) : datum;
            });
            table.clear();
            table.rows.add(newData);
            table.draw();

            var newRowIndex = _.findIndex(newData, function(datum) {
                return datum[primaryKeyField] === primaryKeyId;
            });
            _animateUpdatedRow(table.row(newRowIndex));
        }
    }

    /**
     * Update table from an action outside of the datatable
     * @param table
     */
    function refreshDataTable(table) {
        if (table) {
            table.draw(false);
        } else {
            LayoutFactory.$rebuildDataGridWidgets();
        }
    }

    /**
     * Model will usually have more properties in it's object than what is shown in the datagrid
     * which means we need to map the model to the row datum to match their structures
     * @param model
     * @param datum
     * @private
     */
    function _mapModelToRow(model, datum) {
        var newRowData = {};
        _.each(_.keys(datum), function(key) {
            var displayKey = key + '_display';
            if (model[displayKey]) {
                newRowData[displayKey] = model[displayKey];
            }
            newRowData[key] = model[key];

        });
        return newRowData;
    }

    /**
     * @param row
     * @private
     */
    function _animateUpdatedRow(row) {
        var $row = angular.element(row.node());
        $row.addClass('magictime zoomInSmooth');
        $timeout(function () {
            $row.removeClass('magictime zoomInSmooth');
        }, 800, false);
    }

    /**
     * Edit action button
     * (Note: datatable can only render html NOT jquery objects, which is why we return HTML)
     * @param isEnabled
     * @param href
     * @param entityName
     * @param isLink
     * @returns {string | *}
     */
    function getEditButton(isEnabled, href, entityName, isLink) {
        var $button = _getActionButton(
            isEnabled,
            href,
            entityName,
            gettextCatalog.getString('Edit'),
            'icon-pencil',
            'btn-primary edit-action'
        );
        if (isLink) {
            $button.attr('href',  href);
        }
        _triggerHideFullDetailsRowModal($button);
        return $button[0].outerHTML;
    }

    /**
     * Edit details action button. Triggers the edit details modal
     * @param field
     * @param isEnabled
     * @returns {string}
     */
    function getEditDetailsButton(field, isEnabled) {
        return getActionButton({
            icon: 'icon-pencil',
            disabled: !isEnabled,
            field: field
        });
    }

    /**
     * @private
     */
    function _triggerHideFullDetailsRowModal($button) {
        // Hide Full Row Details Panel if feasible, when edit button is clicked
        $button.attr('onclick', 'if ($(this).closest(".dtr-details").length) { $("div.dtr-bs-modal").modal("hide");}');
    }

    /**
     * Delete action button
     * (Note: datatable can only render html NOT jquery objects, which is why we return HTML)
     * @param isEnabled
     * @param href
     * @param entityName
     * @param tooltip
     * @returns {string | *}
     */
    function getDeleteButton(isEnabled, href, entityName, tooltip) {
        var $button = _getActionButton(
            isEnabled,
            href,
            entityName,
            tooltip || gettextCatalog.getString('Delete'),
            'icon-trash',
            'btn-danger delete-action'
        );
        return $button[0].outerHTML;
    }

    /**
     * Copy action button
     * (Note: datatable can only render html NOT jquery objects, which is why we return HTML)
     * @param isEnabled
     * @param href
     * @param entityName
     * @param tooltip
     * @returns {string | *}
     */
    function getCopyButton(isEnabled, href, entityName, tooltip) {
        var $button = _getActionButton(
            isEnabled,
            href,
            entityName,
            tooltip || gettextCatalog.getString('Copy'),
            'icon-copy',
            'btn-primary copy-action'
        );
        return $button[0].outerHTML;
    }

    /**
     * Return action button element
     * @param isEnabled
     * @param href
     * @param entityName
     * @param tooltip
     * @param icon
     * @param btnClass
     * @returns {*|jQuery|HTMLElement}
     * @private
     */
    function _getActionButton(isEnabled, href, entityName, tooltip, icon, btnClass) {
        var disabled = !isEnabled ? ' disabled' : '';

        var $button = $('<a/>', {
            class: 'datagrid-action btn btn-circle ' + btnClass + disabled,
            title: tooltip
        });

        $button.attr('data-url', href);
        $button.attr('data-name', entityName || 'this row');
        $button.attr('data-is-from-widget', true);

        var $icon = $('<i class="icon '+icon+'"></i>');
        $button.html($icon);

        if (!isEnabled) {
            var $wrapper = $('<span/>', {
                title: tooltip
            });
            $wrapper.attr('data-toggle', 'tooltip');
            $wrapper.html($button);
            return $wrapper;
        } else {
            $button.attr('data-toggle', 'tooltip');
            return $button;
        }
    }

    /**
     * @param options
     * @returns {string | *}
     * @private
     */
    function _getActionControl($el, options) {
        $el.html(options.html || '');
        if (options.tooltip) {
            $el.attr('data-toggle', 'tooltip');
            $el.attr('title', options.tooltip);
        }
        if (options.icon) {
            var $icon = $('<i class="icon '+ options.icon +'"></i>');
            $el.prepend($icon);
        }
        if (options.disabled) {
            $el.attr('disabled', options.disabled);
        }
        $el.attr('data-field', options.field);
        $el.attr('data-id', options.id);
        $el.addClass(options.className || '');
        _triggerHideFullDetailsRowModal($el);
        return $el[0].outerHTML;
    }

    /**
     * @param options
     * @returns {string}
     */
    function getActionButton(options) {
        var $button = $('<a/>', {
            class: 'btn btn-primary btn-circle action-button',
            href: 'javascript:void(0);'
        });
        return _getActionControl($button, options);
    }

    /**
     * @param options
     * @returns {string}
     */
    function getActionLink(options) {
        var $link = $('<a/>', {
            class: 'action-link',
            href: 'javascript:void(0);'
        });
        return _getActionControl($link, options);
    }

    /**
     *
     * @param options
     */
    function bindDeleteRowAction($button, options) {
        $button.click(function() {
            options = options || {};
            options.deleteUrl = $rootScope.util.apiUrl($button.data('url'));
            options.id = _.last($button.data('url').split('/'));
            options.entityName = $button.data('name');

            var deleteOptions = DataGridModelFactory.getDeleteOptions(options);
            UIFactory.confirmDelete(deleteOptions);
        });
    }

    /**
     * @param options
     */
    function bindCopyRowAction($button, options) {
        $button.click(function() {
            options = options || {};
            options.copyUrl = $rootScope.util.apiUrl($button.data('url'));
            options.id = _.last($button.data('url').split('/'));
            options.entityName = $button.data('name');

            if (options.is_custom) {
                options.callback(options.id);
            } else {
                var copyOptions = DataGridModelFactory.getCopyOptions(options);
                UIFactory.confirm(copyOptions);
            }
        });
    }

}
